import { FC, useState, useEffect } from "react";
import {
  Form,
  Button,
  Drawer,
  Input,
  Typography,
  message,
  Switch,
} from "antd";
import { TRecipientCheckDoc } from "../../types";
import { Firebase } from "services";
import { JSONstringifyOrder } from "utils";

const { Title } = Typography;

interface OwnProps {
  recipientId: string;
  check: TRecipientCheckDoc;
  isVisible: boolean;
  onClose: () => void;
}

const EditCheck: FC<OwnProps> = ({
  check,
  isVisible,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState<string | null>(null);
  const [resolved, setResolved] = useState<boolean>();

  const form = Form.useForm()[0];

  useEffect(() => {
    if (check && isVisible) {
      form.setFieldsValue(check);
      setComments(check.comments);
      setResolved(check.isResolved);
    }
  }, [form, check, isVisible]);

  const onUpdateCheck = async () => {
    setIsLoading(true);

    try {
      if (!comments) {
        message.error("You need to provide a comment to update the Check");
        setIsLoading(false);
        return;
      }

      const updateData = {
        comments,
        isResolved: resolved,
      };

      const response = await Firebase.updateRecipientCheck({
        recipientId: check.recipientId,
        checkId: check.id,
        updateData,
      });

      if (response.data.success) {
        message.success(`Check ${check.id} updated successfully`);
        onClose();
      } else {
        message.error('Failed to update check');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title="Edit Check Details"
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      open={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={() => {
          onUpdateCheck();
        }}
      >
        <Title level={5}>Comments</Title>
        <Input.TextArea 
          rows={6} 
          value={comments} 
          onChange={(e) => setComments(e.target.value)}
        />

        <Title level={5}>Resolved</Title>
        <Switch 
          checked={resolved} 
          onChange={(checked) => setResolved(checked)} 
        />

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button 
              disabled={isLoading} 
              htmlType="submit" 
              type="primary"
            >
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

      {check && 
        <>
          <Title level={5}>Check Details</Title>
          <pre>{JSONstringifyOrder(check, 2)}</pre>
        </>
      }
    </Drawer>
  );
};

export default EditCheck;
