import { FC, useState, useEffect } from "react";
import { Button, message, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import { openDoc, TCustomRouteComponentProps } from "utils";
import "./GlobalFeatureFlags.css";

import db from "services/firestore";

const subscribeToGlobalFeatureFlags = (callback) => {
  try {
    const unsubscribe = db
      .collection("featureFlags")
      .doc("global")
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const GlobalFeatureFlags: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [globalFeatureFlags, setGlobalFeatureFlags] = useState(null);

  useEffect(() => {
    const unsubscribe = subscribeToGlobalFeatureFlags((data) => {
      if (data.id) {
        delete data.id;
      }
      setGlobalFeatureFlags(data);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const updateGlobalFeatureFlags = async () => {
    try {
      await db.collection("featureFlags").doc("global").set(globalFeatureFlags);

      message.success("Global feature flags were updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  return globalFeatureFlags ? (
    <div>
      <Title>Global feature flags</Title>

      <Editor
        ace={ace}
        theme="ace/theme/github"
        value={globalFeatureFlags}
        mode="code"
        onChange={(data) => {
          if (!data) {
            setGlobalFeatureFlags({});
            return;
          }
          setGlobalFeatureFlags(data);
        }}
      />

      {allowWrite && (
        <Button
          style={{
            margin: "16px 0",
          }}
          type="primary"
          onClick={updateGlobalFeatureFlags}
        >
          Update
        </Button>
      )}
    </div>
  ) : null;
};

export default GlobalFeatureFlags;
