import { FC, useEffect, useState } from "react";
import { Table, Typography, Tag } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { IIpIdCheckResult, TIpIdMatchScoreDescription, TRecipientCheckDoc } from "./types";
import db from "services/firestore";
import { openQueryWithTimestamp } from "utils";
import EditCheck from "./components/EditCheck/EditCheck";

const { Title } = Typography;

const subscribeToRecipientChecks = (recipientId, callback) => {
  const unsubscribe = db
    .collection("recipients")
    .doc(recipientId)
    .collection("checks")
    .onSnapshot((query) =>
      callback(openQueryWithTimestamp(query, ["_created", "_updated"]))
    );

  return unsubscribe;
};

const Checks: FC<{ recipientId: string }> = ({ recipientId }) => {
  const [checks, setChecks] = useState<TRecipientCheckDoc[]>([]);
  const [selectedCheck, setSelectedCheck] = useState<TRecipientCheckDoc | null>(
    null
  );

  useEffect(() => {
    const unsubscribe = subscribeToRecipientChecks(recipientId, setChecks);

    return unsubscribe;
  }, [recipientId]);

  const checksColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Type of check",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Match status",
      dataIndex: "details",
      key: "details",
      render: (details: IIpIdCheckResult) => {
        return (
          <Tag
            color={
              details.matchScoreDescription === "Strong Match"
                ? "green"
                : details.matchScoreDescription === "Partial Match"
                ? "orange"
                : "red"
            }
          >
            {details.matchScoreDescription}
          </Tag>
        );
      },
    },
    {
      title: "Resolved",
      dataIndex: "isResolved",
      key: "isResolved",
      render: (isResolved: boolean) => {
        return isResolved ? (
          <Tag color="green">Yes</Tag>
        ) : (
          <Tag color="red">No</Tag>
        );
      },
    },
    {
      title: "User explanation",
      dataIndex: "details",
      key: "details",
      render: (details: IIpIdCheckResult) =>
        details.userExplanationForSaveWithWeakMatch,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <Link to="#" onClick={() => setSelectedCheck(record)}>
            {text}
          </Link>
        </span>
      ),
    },
  ];

  return (
    <>
      <Table
        title={() => <Title level={2}>Checks</Title>}
        columns={checksColumns}
        dataSource={checks}
        pagination={false}
      />
      <EditCheck
        recipientId={recipientId}
        check={selectedCheck}
        isVisible={!!selectedCheck}
        onClose={() => setSelectedCheck(null)}
      />
    </>
  );
};

export default Checks;
