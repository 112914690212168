import firebase from "firebase/app";

// OPEN DATABASE QUERY
export const openQuery = <T=any> (query: firebase.firestore.QuerySnapshot) => {
  if (query.empty) {
    return [];
  }
  if (query.docs) {
    const docs: T[] = [];
    query.docs.forEach((doc) => {
      docs.push({
        ...doc.data(),
        id: doc.id,
      } as T);
    });
    return docs;
  }
  return [];
};

// OPEN DATABASE QUERY WITH TIMESTAMP INSIDE EVERY DOCUMENT
export const openQueryWithTimestamp = <T=any>(
  query: firebase.firestore.QuerySnapshot,
  timestampFields: string[]
) => {
  if (query.empty) {
    return [];
  }
  if (query.docs) {
    const docs: T[] = [];
    query.docs.forEach((doc) => {
      const docData = doc.data();

      const parsedDoc = {
        ...docData,
        id: doc.id,
      } as T;

      timestampFields.forEach((field) => {
        try{
          parsedDoc[field] = docData[field]?.toDate();
        } catch (error) {
          console.log(`Couldn't convert ${field} value ${docData[field]} to date`);
        }
      });

      docs.push(parsedDoc);
    });

    return docs;
  }
  return [];
};

// OPEN DATABASE DOC
export const openDoc = (doc: firebase.firestore.DocumentSnapshot) => {
  if (!doc.exists) {
    return null;
  }
  return {
    ...doc.data(),
    id: doc.id,
  };
};

// OPEN DATABASE DOC WITH TIMESTAMP
export const openDocWithTimestamp = (
  doc: firebase.firestore.DocumentSnapshot,
  timestampFields: string[]
) => {
  if (!doc.exists) {
    return null;
  }

  const docData = doc.data();

  const parsedDoc = {
    ...doc.data(),
    id: doc.id,
  };

  timestampFields.forEach((field) => {
    try{
      parsedDoc[field] = docData[field]?.toDate();
    } catch (error) {
      console.log(`Couldn't convert ${field} value ${docData[field]} to date`);
    }
  });

  return parsedDoc;
};
