import { FC } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { Login } from "pages";

const PublicRoutes: FC = () => {
  const location = useLocation<{ from?: string }>();

  return (
    <>
      <Route path="/login" component={Login} />
      <Redirect
        to={{
          pathname: "/login",
          state: {
            from: location.pathname.includes("/login")
              ? location.state?.from
              : location.pathname,
          },
        }}
      />
    </>
  );
};

export default PublicRoutes;
