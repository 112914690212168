import { useEffect } from "react";
import { useStoreActions } from "state";
import { subscribeToPageAccess } from "services/firebase";

const useBootstrap = () => {
  const { setRoleBasedAdminPageAccess } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );

  useEffect(() => {
    // Subscribe to Page Access reference data
    const unsubscribe = subscribeToPageAccess((data) =>
      setRoleBasedAdminPageAccess(data)
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [setRoleBasedAdminPageAccess]);
};

export default useBootstrap;
