import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import { Firebase } from "services";

import { IContract, ITransfer, ITransferInput } from "types";

export interface TransfersStateModel {
  transfers: ITransfer[];
  transfersByRateContract: Computed<
    TransfersStateModel,
    (rateContractId: IContract["id"]) => ITransfer[]
  >;
  transferById: Computed<
    TransfersStateModel,
    (rateContractId: ITransfer["id"]) => ITransfer | null
  >;
  setState: Action<TransfersStateModel, [string, any]>;
  subscribeToTransfers: Thunk<
    TransfersStateModel,
    null,
    null,
    null,
    () => void
  >;
  createTransfer: Thunk<TransfersStateModel, ITransferInput>;
}

export const TransfersState: TransfersStateModel = {
  transfers: [],
  transfersByRateContract: computed(
    (state) => (rateContractId) =>
      state.transfers.filter((item) => item.contractId === rateContractId)
  ),
  transferById: computed(
    (state) => (transferId) =>
      state.transfers.find((item) => item.id === transferId) || null
  ),
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  subscribeToTransfers: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToTransfers({
      callback: (rateContracts) => setState(["transfers", rateContracts]),
    });

    return subscriber;
  }),
  createTransfer: thunk(async (actions, payload) => {
    const data = await Firebase.createTransfer(payload);

    return data;
  }),
};
