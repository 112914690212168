import React, { FC, useState, useEffect } from "react";
import { Table, Typography } from "antd";

import { useStoreActions, useStoreState } from "state";
import dayjs from "dayjs";
import "./Overview.css";
import { Firebase } from "services";
import { Link } from "react-router-dom";
import { IContract, ITransfer, TRANSFER_STATUS } from "types";
import { parseRateWithPrecision, toCurrencyNumber } from "utils";
import DocViewer from "./components/DocViewer";

const { Title } = Typography;

interface IForAttentionDocument {
  id: string;
  type: 'conversion' | 'contract' | 'transfer' | 'bulkPayment';
  _owner?: string;
  _created: string;
  _updated?: string;
  _createdBy: string;
  _updatedBy?: string;
  sellCurrency: string;
  sellAmount: number;
  buyAmountsBreakdown: {
    currency: string;
    amount: number;
    rate: number;
    sellAmount: number;
  }[];
  status: string;
  dateToMonitor: { type: string; date?: string };
}

const prebooksColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Sell",
    dataIndex: "sell",
    key: "sell",
    render: (text, record: IContract) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.totalAmount)}&nbsp;<b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Buy",
    dataIndex: "buy",
    key: "buy",
    render: (text, record: IContract) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.buyAmount)}&nbsp;<b>{record.buyCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
    render: (value) => {
      return(
        <>
          {parseRateWithPrecision(value)}
        </>
      )
    }
  },
  {
    title: "Flex (Fee)",
    dataIndex: "flexFeeAmount",
    key: "flexFeeAmount",
    render: (value) => {
      const isflex = !!value;
      return(
        <>
          {isflex ? `Yes (${toCurrencyNumber(value)})` : 'No'}
        </>
      )
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Expiry",
    dataIndex: "expiryDate",
    key: "expiryDate",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/contract/" + record.id}>{text}</Link>
        </span>
      );
    },
  },
  {
    title: "Entity ID",
    dataIndex: "_owner",
    key: "_owner",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record._owner}>{text}</Link>
        </span>
      );
    },
  },
];

const transfersColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Sell",
    dataIndex: "sell",
    key: "sell",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.sellAmount)}&nbsp;<b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Buy",
    dataIndex: "buy",
    key: "buy",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.buyAmount)}&nbsp;<b>{record.buyCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Funding required",
    dataIndex: "payAmount",
    key: "payAmount",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.payAmount)}&nbsp;<b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Pay by (GMT)",
    dataIndex: "status",
    key: "status",
    render: (text, record: ITransfer) => `${record.payByDate} ${record.payByTimeGMT}`
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record: ITransfer) => {
      let status = record.status.toString();
      status === TRANSFER_STATUS.awaitingPayment && (status = 'Awaiting Payment')
      status === TRANSFER_STATUS.paymentSent && (status = 'Sent')
      return (<>{status}</>)
    }
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/transfer/" + record.id}>{text}</Link>
        </span>
      );
    },
  },
  {
    title: "Recipient ID",
    dataIndex: "recipientId",
    key: "recipientId",
    render: (text, record: ITransfer) => {
      return (
        <span>
          <Link to={"/app/recipient-detail/" + record.recipientId}>{text}</Link>
        </span>
      );
    },
  },
  {
    title: "Entity ID",
    dataIndex: "_owner",
    key: "_owner",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record._owner}>{text}</Link>
        </span>
      );
    },
  },
];

const transactionsForAttentionColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (text, record: ITransfer) => {
      const firstCharacterToUpperCase = (str: string) => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
      };
      return (<>{firstCharacterToUpperCase(text)}</>)
    }
  },
  {
    title: "Sell",
    dataIndex: "sell",
    key: "sell",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.sellAmount)}&nbsp;<b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Buy",
    dataIndex: "buy",
    key: "buy",
    render: (text, record: IForAttentionDocument) => {
      return (
        <>
          {record.buyAmountsBreakdown?.map((item) => {
            return (
              <div className="currencyAmount">
                {toCurrencyNumber(item.amount)}&nbsp;
                <b>{item.currency}</b>
              </div>
            )
          })}
        </>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record: ITransfer) => {
      let status = record.status.toString();
      status === TRANSFER_STATUS.awaitingPayment && (status = 'Awaiting Payment')
      status === TRANSFER_STATUS.paymentSent && (status = 'Sent')
      return (<>{status}</>)
    }
  },
  {
    title: "Date to monitor",
    dataIndex: "dateToMonitor",
    key: "dateToMonitor",
    render: (text, record: IForAttentionDocument) => {
      return (<>{record.dateToMonitor.type}: {record.dateToMonitor.date}</>)
    }
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record: IForAttentionDocument) => {
      let link = '';
      if (record.type === 'transfer') {
        link = `/app/transfer/${record.id}`
      }
      else if (record.type === 'contract') {
        link = `/app/contract/${record.id}`
      }
      else if (record.type === 'conversion') {
        link = `/app/conversion/${record.id}`
      }
      else if (record.type === 'bulkPayment') {
        link = `/app/bulkPayment/${record.id}`
      }
      return (
        <span>
          <Link to={link}>{text}</Link>
        </span>
      );
    },
  },
  {
    title: "Entity ID",
    dataIndex: "_owner",
    key: "_owner",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record._owner}>{text}</Link>
        </span>
      );
    },
  },
];

const Overview: FC = () => {
  const { rateContracts } = useStoreState((state) => state.RateContractsState);
  const { transfers } = useStoreState((state) => state.TransfersState);
  const [events, setEvents] = useState<any[]>([]);
  const [transactionsForAttention, setTransactionsForAttention] = useState<any[]>([]);
  const { subscribeToTransfers } = useStoreActions(
    ({ TransfersState }) => TransfersState
  );
  const { subscribeToRateContracts } = useStoreActions(
    ({ RateContractsState }) => RateContractsState
  );

  const [docToView, setDocToView] = useState(null);

  const eventsColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (text, record) => {
        const created = dayjs(text).format("DD MMM YYYY HH:mm:ss");
        return (
          <span>
            <Link to="#" onClick={() => setDocToView(record)}>{created}</Link>
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Action code",
      dataIndex: "actionCode",
      key: "actionCode",
    },
    {
      title: "Success",
      dataIndex: "_success",
      key: "_success",
      render: (success) => success ? 'TRUE' : 'FALSE',
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
  ];

  useEffect(() => {
      Firebase.getTransactionsForAttention().then((data) => setTransactionsForAttention(data));
  }, []);

  useEffect(() => {
    let unsubscribeTransfers = null;
    let unsubscribeRateContracts = null;
    let unsubscribeEvents = null;

    unsubscribeTransfers = subscribeToTransfers();
    unsubscribeRateContracts = subscribeToRateContracts();
    unsubscribeEvents = Firebase.subscribeToEvents({
      callback: (data) => setEvents(data),
    });

    return () => {
      if (unsubscribeTransfers) {
        unsubscribeTransfers();
      }
      if (unsubscribeRateContracts) {
        unsubscribeRateContracts();
      }
      if (unsubscribeEvents) {
        unsubscribeEvents();
      }
    };
  }, [subscribeToRateContracts, subscribeToTransfers]);

  return (
    <div>
      <>
        <DocViewer
          doc={docToView}
          isVisible={!!docToView}
          onClose={() => setDocToView(null)}
        />
      </>
      <>
      <Table
        title={() => <Title level={2}>Transactions Requiring Attention</Title>}
        columns={transactionsForAttentionColumns}
        dataSource={transactionsForAttention}
      />

      <Table
        title={() => <Title level={2}>Last 5 Prebooks</Title>}
        columns={prebooksColumns}
        dataSource={rateContracts.slice(0, 5)}
        pagination={false}
      />

      <Table
        title={() => <Title level={2}>Last 10 Transfers</Title>}
        columns={transfersColumns}
        dataSource={transfers.slice(0, 10)}
        pagination={false}
      />

      <Table
        title={() => <Title level={2}>Last 100 Events</Title>}
        columns={eventsColumns}
        dataSource={events}
      />
      </>
    </div>
  );
};

export default Overview;
