import { message } from "antd";
import { AxiosPrivateFirebaseInstance } from "settings";
// import db from "services/firestore";
// import { IWriteEventLog } from "pages/WriteLogDetails/interfaces";
// import { openQueryWithTimestamp } from "utils";

// const COLLECTION_NAME = "writeEvents";
// const SUB_COLLECTION_NAME = "allAttempts";

export const cancelPaymentFromExternalInvoice = async ({
  entityId, invoiceId, sourceSystemInvoiceId, paymentId
}:{
  entityId: string, invoiceId: string, paymentId: string, sourceSystemInvoiceId: string
}) => {
  await AxiosPrivateFirebaseInstance.delete(
    `/admin_actions/integrations/${entityId}/invoices/${invoiceId}/payments/${paymentId}`,
    {
      data: {
        sourceSystemInvoiceId
      }
    }
  )
    .then((res: any) => {
      if (res?.data?.success) {
        message.success(
          "Successfully Queued"
        );
      } else {
        message.error(res?.data?.error);
      }
    })
    .catch((err) => {
      console.log(err);
      message.error(err.message);
    });
};