import { FC } from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "hoc/PrivateRoute";
import { PRIVATE_ROUTES } from "../../../variables";
import { useStoreState } from "state";

const PrivateRoutes: FC = () => {
  const routesData = useStoreState(
    (state) => state.ReferenceDataState.routesData
  );
  const location = useLocation<{ from?: string }>();

  return (
    <Switch>
      {PRIVATE_ROUTES.map(
        ({ Component, pageKey, subPage = "", queryParams = "" }) => {
          return (
            <PrivateRoute
              key={`/app/${pageKey}${subPage}${queryParams}`}
              path={`/app/${pageKey}${subPage}${queryParams}`}
              component={Component}
              pageKey={pageKey}
              exact
            />
          );
        }
      )}
      {routesData.defaultPath && (
        <Redirect
          to={location.state?.from ?? `/app/${routesData.defaultPath}`}
        />
      )}
    </Switch>
  );
};

export default PrivateRoutes;
