import { openDoc } from "utils";
import db from "services/firestore";

export interface GetUserParams {
  id: string;
}

export const getUser = async ({ id }: GetUserParams) => {
  try {
    const user = await db
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => openDoc(doc));

    if (user) {
      return user;
    } else {
      console.warn("User not found.");
    }
  } catch (error) {
    console.warn(error.message);
  }
};

export interface GetUserEntityParams {
  entityId: string;
}

export const getUserEntity = async ({ entityId }: GetUserEntityParams) => {
  try {
    const entity = await db
      .collection("entities")
      .doc(entityId)
      .get()
      .then((doc) => openDoc(doc));

    if (entity) {
      return entity;
    } else {
      console.warn("User not found.");
    }
  } catch (error) {
    console.warn(error.message);
  }
};
