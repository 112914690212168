import { FC, useEffect, useState } from "react";
import { Table, Typography, Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { Firebase } from "services";

const { Title } = Typography;
const { Column } = Table;

const Users: FC = () => {
  const [userLogs, setUserLogs] = useState([]);
  const [, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");

  useEffect(() => {
    const unsubscribe = Firebase.subscribeToUserLogs({
      callback: (data) => setUserLogs(data),
    });

    return () => {
      unsubscribe && unsubscribe();
    };
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  return (
    <div>
      <Table
        title={() => <Title level={2}>Users Logs</Title>}
        dataSource={userLogs}
      >
        <Column
          title="ID"
          key="id"
          dataIndex="id"
          {...getColumnSearchProps("id")}
        />
        <Column
          title="User ID"
          key="userId"
          dataIndex="userId"
          {...getColumnSearchProps("userId")}
        />
        <Column
          title="Type"
          key="type"
          dataIndex="type"
          {...getColumnSearchProps("type")}
        />
        <Column
          title="Destination"
          key="destination"
          dataIndex="destination"
          {...getColumnSearchProps("destination")}
        />
        <Column
          title="Message"
          key="message"
          dataIndex="message"
          render={(value) => {
            if (typeof value === "string") {
              return value.substring(0, 400);
            }

            return JSON.stringify(value).substring(0, 400);
          }}
          {...getColumnSearchProps("message")}
        />
        <Column
          title="Created"
          key="_createdAt"
          dataIndex="_createdAt"
          render={(date) => dayjs(date).format("DD MMM YYYY HH:mm:ss")}
          {...getColumnSearchProps("_createdAt")}
        />
      </Table>
    </div>
  );
};

export default Users;
