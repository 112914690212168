import React, { useState } from "react"
import { Button, DatePicker, Modal } from "antd";
import { Firebase } from "services";
const { RangePicker } = DatePicker;


interface OwnProps {
  entityId: string;
}

const ProfitAndLoss: React.FC<OwnProps> = ({entityId}) => {
  const [dates, setDates] = useState<{from?: string, to?: string}>({from: undefined, to: undefined})
  const [profitAndLoss, setProfitAndLoss] = useState<string>()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const onSubmitHandler = async () => {
    const result = await Firebase.getProfitAndLossReport({entityId, from: dates.from, to: dates.to});
    const data = result?.data.data;
    const resultAsString = JSON.stringify(data, null, 2);
    setProfitAndLoss(resultAsString);
    setIsModalOpen(true);
  }

  const onDateChange = ([from, to]) => {
    const fromIso = new Date(from).toISOString();
    const toIso = new Date(to).toISOString();

    setDates({
      from: fromIso,
      to: toIso
    })
  }

  const validData = dates.from !== undefined && dates.to !== undefined;


  return (
    <div style={{ margin: "0px 25px 0px 0px", float: "right" }}>
      <RangePicker onChange={onDateChange} />
      <Button
        onClick={onSubmitHandler}
        disabled={!validData}
      >
        Get profit and loss Report
      </Button>
      <Modal title="Profit and loss" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={800}>
        <pre>{profitAndLoss}</pre>
      </Modal>
    </div>
  )
}

export default ProfitAndLoss