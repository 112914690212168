import { FC, useState, useEffect } from 'react';
import { Column, DualAxes } from '@ant-design/charts';
import { Typography } from "antd";
import db from 'services/firestore';
import { ITransfer, TRANSFER_STATUS } from 'types';
import { convertFireStoreTimestampToDateString } from "utils";

const { Title, } = Typography;

const getSalesDataForCharts = async () => {
  const results = [];
  await db.collection('transfers')
    // .where('status', '==', 'payment_sent')
    .get()
    .then(dataSnapshot => dataSnapshot.docs)
    .then(docs =>
      docs.forEach(doc => {
        const transfer = doc.data() as ITransfer;
        if (transfer.status !== TRANSFER_STATUS.paymentCancelled) {
          // const monthString = (transfer.payByDate as string).slice(0, 7);
          const monthString = convertFireStoreTimestampToDateString(transfer._created).slice(0, 7);
          const amount = transfer.sellAmount;
          const currency = transfer.sellCurrency;
          const key = `${monthString}-${currency}`;
          if (results[key]) {
            results[key].sales += amount;
            results[key].count += 1;
          } else {
            results[key] = { month: monthString, sellCurrency: currency, sales: amount, count: 1 };
          }
        }
      })
    );

  const myArray = [];
  Object.entries(results).forEach(([key, value]) => {
    myArray.push(value);
  });

  return myArray.sort((a, b) => (a.month < b.month ? -1 : 1));
};

const getTransferFeeDataForCharts = async () => {
  const results = [];
  await db.collection('entities')
    .doc('house')
    .collection('transactions')
    // .where('type', '==', 'transferFee')
    .where('matched', '==', true)
    .get()
    .then(dataSnapshot => dataSnapshot.docs)
    .then(docs => docs.filter(item => (item.data().matchedWith as string).indexOf('funding') < 0))
    .then(docs =>
      docs.forEach(doc => {
        const houseTransactions = doc.data();
        // const monthString = (transfer.payByDate as string).slice(0, 7);
        const monthString = convertFireStoreTimestampToDateString(houseTransactions._created).slice(
          0,
          7
        );
        const { currency, amount } = houseTransactions;
        const key = `${monthString}-${currency}`;
        if (results[key]) {
          results[key].fees += amount;
          results[key].count += 1;
        } else {
          results[key] = { month: monthString, currency, fees: amount, count: 1 };
        }
        return true;
      })
    );

  const myArray = [];
  Object.entries(results).forEach(([key, value]) => {
    myArray.push(value);
  });

  return myArray.sort((a, b) => (a.month < b.month ? -1 : 1));
};

const arrayGroupBy = (dataIn: any[], groupBy: string, aggregate: string) => {
  const results = [];
  dataIn.forEach(data => {
    if (results[data[groupBy]]) {
      results[data[groupBy]][aggregate] += data[aggregate];
    } else {
      results[data[groupBy]] = { [groupBy]: data[groupBy], [aggregate]: data[aggregate] };
    }
    return true;
  });

  const myArray = [];
  Object.entries(results).forEach(([key, value]) => {
    myArray.push(value);
  });

  return myArray;
};

const Charts: FC = () => {
  const [salesData, setSalesData] = useState([]);
  const [salesDataCount, setSalesDataCount] = useState([]);
  const [transferFeeData, setTransferFeeData] = useState([]);

  const getSalesChartConfig = () => ({
    data: [salesData, salesDataCount],
    xField: 'month',
    yField: ['sales', 'count'],
    // seriesField: 'sellCurrency',
    // columnWidthRatio: 0.8,
    geometryOptions: [
      {
        geometry: 'column',
        isGroup: true,
        seriesField: 'sellCurrency',
        // color : 'green',
      },
      {
        geometry: 'line',
        lineStyle: { lineWidth: 2},
      },
    ],
    meta: {
      count: {
        min: 0,
      },
    }
    // xAxis: {
    //   label: {
    //     autoHide: false,
    //     autoRotate: false,
    //   },
    // },
  });

  const getTransferFeeChartConfig = () => ({
    data: transferFeeData,
    isGroup: true,
    xField: 'month',
    yField: 'fees',
    seriesField: 'currency',
    // label: {
    //   position: 'top',
    //   layout: [
    //     { type: 'interval-adjust-position' },
    //     { type: 'interval-hide-overlap' },
    //     { type: 'adjust-color' },
    //   ],
    // } as any,
  });


  useEffect(() => {
    console.log('calling useEffect');

    if (salesData.length === 0) {
      console.log('getting sales data');
      getSalesDataForCharts()
        .then(data => {
          const countData = arrayGroupBy(data, 'month', 'count')
          console.log('data:', data);
          setSalesData(data);
          console.log('data-count:', countData);
          setSalesDataCount(countData);
        })
        .then(() => {
          console.log('salesData:', salesData);
          console.log('countData:', salesDataCount);
        });
    }

    if (transferFeeData.length === 0) {
      console.log('getting transferFee data');
      getTransferFeeDataForCharts()
        .then(data => setTransferFeeData(data))
        .then(() => {
          console.log('transferFeeData:', transferFeeData);
        });
    }
    
  });


  return (
    <div>
      {salesData.length > 0 && (
        <div>
          <Title level={2}>Sales</Title>
          <DualAxes {...getSalesChartConfig()} />
        </div>
      )}
      <br /><br /><br />
      {transferFeeData.length > 0 && (
        <div>
          <Title level={2}>Transfer Fees and other CFs in and out of House account</Title>
          <Column {...getTransferFeeChartConfig()} />
        </div>
      )}
      <br /><br /><br />
    </div>
  );
};

export default Charts;
