import { FC, useState } from "react";
import { Button, Input, Row, Form } from "antd";

import { useStoreActions } from "state";
import { useRecaptchaVerifier } from "hooks";
import firebase from "firebase/app";
import { Firebase } from "services";
import { IUser } from "types";
import { message } from "antd";
import { getRolesFromClaims, validateUserClaims } from "utils";

const Login: FC = () => {
  const { signInWithGoogle, setUser } = useStoreActions(
    (actions) => actions.UserState
  );
  const [stepOneComplete, setStepOneComplete] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [authResolver, setAuthResolver] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaContainer = useRecaptchaVerifier("recaptcha");

  const form = Form.useForm()[0];

  const signIn = async () => {
    const resolver = await signInWithGoogle(recaptchaContainer);

    if (resolver?.hints) {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      const verificationResp = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaContainer
      );

      setUserPhone(resolver.hints[0].phoneNumber);
      setVerificationId(verificationResp);
      setAuthResolver(resolver);
      setStepOneComplete(true);
    } else {
      if (resolver?.message) {
        message.error(resolver.message);
      }
    }
  };

  const onSubmitCode = async (values) => {
    const cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      values.verificationCode
    );

    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    try {
      setIsLoading(true);
      const data = await authResolver.resolveSignIn(multiFactorAssertion);

      if (data?.user) {
        const { user } = data;
        const { claims } = await user.getIdTokenResult();
        const isValidUser = validateUserClaims(claims);

        if (user && isValidUser) {
          const { uid } = user;

          const userFromDatabase = await Firebase.getUser({
            id: uid,
          });

          const userToSave = {
            ...userFromDatabase,
            roles: getRolesFromClaims(claims),
          };

          if (userFromDatabase) {
            setUser(userToSave as IUser);
          }
        } else {
          message.error("Account has no access.");
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "calc(100vh - 24px)",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!stepOneComplete && (
        <Button type="primary" onClick={signIn}>
          Sign In With Google
        </Button>
      )}

      {stepOneComplete && (
        <div style={{ width: "300px" }}>
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            onFinish={async ({ fileUpload, ...values }) => {
              // submit logic here
              onSubmitCode(values);
            }}
          >
            <Row gutter={16}>
              <Form.Item
                style={{ width: "100%" }}
                name="verificationCode"
                label={`We’ve just sent you an SMS with a verification code to ${userPhone}`}
              >
                <Input style={{ width: "100%" }} placeholder="" />
              </Form.Item>
            </Row>
            <Form.Item>
              <div
                style={{
                  textAlign: "left",
                  marginLeft: -8,
                }}
              >
                <Button disabled={isLoading} htmlType="submit" type="primary">
                  Continue
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}

      <div id="recaptcha" />
    </div>
  );
};

export default Login;
