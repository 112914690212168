import { FC, useState, useEffect } from "react";
import { Button, message, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import { openDoc, TCustomRouteComponentProps } from "utils";
import "./EntityFeatureFlags.css";

import db from "services/firestore";

const subscribeToEntityFeatureFlags = (callback) => {
  try {
    const unsubscribe = db
      .collection("featureFlags")
      .doc("perEntity")
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const EntityFeatureFlags: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [perEntityFeatureFlags, setPerEntityFeatureFlags] = useState(null);

  useEffect(() => {
    const unsubscribe = subscribeToEntityFeatureFlags((data) => {
      if (data.id) {
        delete data.id;
      }
      setPerEntityFeatureFlags(data);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const updateEntityFeatureFlags = async () => {
    try {
      await db
        .collection("featureFlags")
        .doc("perEntity")
        .set(perEntityFeatureFlags);

      message.success("Entity feature flags were updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  return perEntityFeatureFlags ? (
    <div>
      <Title>Entity feature flags</Title>

      <Editor
        ace={ace}
        theme="ace/theme/github"
        value={perEntityFeatureFlags}
        mode="code"
        onChange={(data) => {
          if (!data) {
            setPerEntityFeatureFlags({});
            return;
          }
          setPerEntityFeatureFlags(data);
        }}
      />

      {allowWrite && (
        <Button
          style={{
            margin: "16px 0",
          }}
          type="primary"
          onClick={updateEntityFeatureFlags}
        >
          Update
        </Button>
      )}
    </div>
  ) : null;
};

export default EntityFeatureFlags;
