import { FC, useEffect, useState } from "react";
import { Table, Typography, Tag } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import db from "services/firestore";
import { openQueryWithTimestamp } from "utils";
import EditCheck from "./components/EditCheck/EditCheck";
import { TTransferCheckDoc } from "types/transfers";

const { Title } = Typography;

const subscribeToTransferChecks = (
  transferId: string,
  callback: (query: TTransferCheckDoc[]) => void
) => {
  const unsubscribe = db
    .collection("transfers")
    .doc(transferId)
    .collection("checks")
    .onSnapshot((query) =>
      callback(openQueryWithTimestamp(query, ["_created", "_updated"]))
    );

  return unsubscribe;
};

const Checks: FC<{ transferId: string }> = ({ transferId }) => {
  const [checks, setChecks] = useState<TTransferCheckDoc[]>([]);
  const [selectedCheck, setSelectedCheck] = useState<TTransferCheckDoc | null>(
    null
  );

  useEffect(() => {
    const unsubscribe = subscribeToTransferChecks(transferId, setChecks);

    return unsubscribe;
  }, [transferId]);

  const checksColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Updated",
      dataIndex: "_updated",
      key: "_updated",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Resolved",
      dataIndex: "isResolved",
      key: "isResolved",
      render: (isResolved: boolean) => {
        return isResolved ? (
          <Tag color="green">Yes</Tag>
        ) : (
          <Tag color="red">No</Tag>
        );
      },
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <Link to="#" onClick={() => setSelectedCheck(record)}>
            {text}
          </Link>
        </span>
      ),
    },
  ];

  return (
    <>
      <Table
        title={() => <Title level={2}>Checks</Title>}
        columns={checksColumns}
        dataSource={checks}
        pagination={false}
      />
      <EditCheck
        transferId={transferId}
        check={selectedCheck}
        isVisible={!!selectedCheck}
        onClose={() => setSelectedCheck(null)}
      />
    </>
  );
};

export default Checks;
