import { ISystemFields } from "types";

export const allowedLedgerAccountNames = [
  'world',
  'world:cc',
  'pending:in',
  'available',
  'pending:out',
  'safeguarding',
] as const;

export type TLedgerAccountNames = (typeof allowedLedgerAccountNames)[number];

type TLedgerStatus = 'pending' | 'applied' | 'failed';

export type TLedgerTransactionSource = 'transfer' | 'conversion' | 'funding' | 'internalTransfer' | 'manualEntry';
export interface ILedgerPostingItem {
  amount: number;
  currency: string;
  from: TLedgerAccountNames;
  to: TLedgerAccountNames;
}

export interface ILedgerTransactionInput {
  /** Usually the entityId, house or safeguarding */
  ledger: string;
  /** Monetary movements between accounts */
  postings: ILedgerPostingItem[];
  /** The time at which the transaction is considered to have occurred. */
  transactionTimestamp?: string;
  description?: string;
  idempotencyKey: string;
  source: TLedgerTransactionSource;
  sourceEvent: string;
  sourceId: string;
  /** Metadata */
  relatedInformation?: {
    [key: string]: any;
  };
}

export interface ILedgerTransactionUpdate {
  description?: string;
  /** The time at which the transaction is considered to have occurred. */
  transactionTimestamp?: string;
  /** Metadata */
  relatedInformation?: {
    [key: string]: any;
  };
}

export interface ILedgerTransaction extends ILedgerTransactionInput {
  /** The time at which the transaction was submitted to the ledger. It is usually the machine clock time. */
  requestTimestamp: string;
  /** The time at which the transaction is considered to have occurred. */
  transactionTimestamp: string;
  /* The time at which the transaction was applied to the ledger. */
  appliedTimestamp?: string;

  postingsImpact: { [account: string]: number };

  /**
   * Can be omitted, but will then be defaulted to "applied".
   * Status is "pending" if the initial write was unsuccessful because it failed validation rules
   * This can be a temporal issue because of events arriving out of order.
   * A process will reattempt to write the entry to the ledger a few times (configurable) before failing.
   */
  status: TLedgerStatus;
  /** While status=pending, this represents how often the record has been reattempted */
  retryCount?: number;
}

export interface ILedgerTransactionDoc extends ILedgerTransaction, ISystemFields {
  status: TLedgerStatus;
}

export interface ILedgerSummaryUpdate {
  _lastAppliedTransactionId: string;
  [account: string]: any;
}

export interface ILedgerSummaryUpdateDoc extends ILedgerSummaryUpdate, ISystemFields {
  status: TLedgerStatus;
}

type TLedgerSummaryAccountsAndValues = Record<string, number>;

export type ILedgerSummaryDoc = TLedgerSummaryAccountsAndValues &
  ISystemFields & {
    _lastAppliedTransactionId: string;
  };
