import { FC } from "react";
import { Layout } from "antd";
import { Routes } from "routes";
import Sidebar from "./Sidebar/Sidebar";
import { Content } from "antd/lib/layout/layout";
import { useStoreState } from "state";

const LayoutComponent: FC = () => {
  const userId = useStoreState((state) => state.UserState.userId);

  return (
    <Layout>
      {!!userId && <Sidebar />}
      <Layout
        className="site-layout"
        style={{ marginLeft: 200, minHeight: "100vh" }}
      >
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
