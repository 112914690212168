import { Checkbox, Switch } from "antd";

type Props = {
  showIsIgnored: boolean, 
  showErrors: ('other' | 'permission' | 'notImplemented')[],
  setShowIsIgnored: (value: boolean) => void,
  setShowErrors: (value: ('other' | 'permission' | 'notImplemented')[]) => void,
  rerunAllOnClick: () => void,
  allowWrite: boolean;
}

const Controls = ({
  showIsIgnored,
  setShowIsIgnored,
  rerunAllOnClick,
  showErrors,
  setShowErrors,
  allowWrite,
}: Props) =>
(
    <>
      Show: <Checkbox.Group value={showErrors} onChange={(e) => setShowErrors(e as ('other' | 'permission' | 'notImplemented')[])}>
        <Checkbox value="other">Errors</Checkbox>
        <Checkbox value="permission">Permission errors</Checkbox>
        <Checkbox value="notImplemented">Not Implemented errors</Checkbox>
      </Checkbox.Group>

      <span style={{marginLeft: 30}}>
        Show Ignored <Switch checked={showIsIgnored} onChange={(e) => setShowIsIgnored(e)} />
      </span>

      {/* Remove button temporarily since it's dangerous to execute it at the moment
       { allowWrite && (
        <Button onClick={rerunAllOnClick} style={{marginLeft: 20}}>Re-run all failed jobs</Button>
      )} */}
    </>
);

export default Controls;
