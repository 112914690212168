import { useEffect } from "react";
import { Firebase } from "services";
import { useStoreActions } from "state";
import { getRolesFromClaims, validateUserClaims } from "utils";

const useAuth = () => {
  const { setIsAuthDone, getUser, setUser } = useStoreActions(
    (actions) => actions.UserState
  );

  useEffect(() => {
    const authListener = Firebase.auth.onAuthStateChanged(
      async (userFromAuth) => {
        if (userFromAuth) {
          const { claims } = await userFromAuth.getIdTokenResult();
          const isValidUser = validateUserClaims(claims);

          if (isValidUser) {
            const { uid } = userFromAuth;

            await getUser({ id: uid, roles: getRolesFromClaims(claims) });
          }
        } else {
          setUser(null);
        }

        setIsAuthDone(true);
      }
    );

    return () => {
      authListener();
    };
  }, [setUser, getUser, setIsAuthDone]);
};

export default useAuth;
