import { FC, useState, useEffect,  } from "react";
import { useLocation } from "react-router-dom";
import { Table, Typography } from "antd";
import "jsoneditor-react/es/editor.min.css";
import "brace/mode/json";
import "brace/theme/github";

import { openQuery, TCustomRouteComponentProps } from "utils";
import "./OpenBankingCollectionViewer.css";

import db from "services/firestore";

const mapPathnameToCollection = (pathname: string) => {
  switch (pathname) {
    case "/app/ob-data/beneficiaries":
      return "obAccountBeneficiaries";
    case "/app/ob-data/direct-debits":
      return "obAccountDirectDebits";
    case "/app/ob-data/periodic-payments":
      return "obAccountPeriodicPayments";
    case "/app/ob-data/scheduled-payments":
      return "obAccountScheduledPayments";
    case "/app/ob-data/transactions":
      return "obAccountTransactions";
    default:
      console.error('Unable to map pathname to collection', pathname);
      return undefined
  }
}

const mapPathnameToPageTitle = (pathname: string) => {
  switch (pathname) {
    case "/app/ob-data/beneficiaries":
      return "Beneficiaries";
    case "/app/ob-data/direct-debits":
      return "Direct Debits";
    case "/app/ob-data/periodic-payments":
      return "Periodic Payments";
    case "/app/ob-data/scheduled-payments":
      return "Scheduled Payments";
    case "/app/ob-data/transactions":
      return "Transactions";
    default:
      return "Unknown";
  }
}


const subscribeToData = (callback, pathname: string) => {
  try {
    const collectionName = mapPathnameToCollection(pathname);
    if(!collectionName) {
      return () => {}
    }

    const unsubscribe = db
      .collection(collectionName)
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const OpenBankingCollectionViewer: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [data, setData] = useState([]);
  const location = useLocation()

  useEffect(() => {
    const unsubscribe = subscribeToData((data) => setData(data), location.pathname);

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [location.pathname]);


  const obDataColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Raw Data",
      render: (data) => (
        <>{JSON.stringify(data)}</>
      ),
    },
  ];

  return (
    <div>
      <Title>OB {mapPathnameToPageTitle(location.pathname)} data</Title>
      <Table columns={obDataColumns} dataSource={data} />
    </div>
  );
};

export default OpenBankingCollectionViewer;
