import { FC } from "react";
import { Drawer } from "antd";
import "antd/dist/antd.css";
import { JSONstringifyOrder } from "utils";

interface OwnProps {
  doc: any;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const DocViewer: FC<OwnProps> = ({
  doc: onboardingItem,
  isVisible,
  onClose,
}) => {

  return (
    <Drawer
      title={`View Document`}
      width={720}
      onClose={() => onClose()}
      open={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <pre>{onboardingItem && JSONstringifyOrder(onboardingItem, 2)}</pre>

    </Drawer>
  );
};

export default DocViewer;
