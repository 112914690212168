import { AxiosPrivateFirebaseInstance } from "settings";

export const getBalancesReport = async (asAtDate: string) => {
  const result = await AxiosPrivateFirebaseInstance.get(
    `/admin_actions/reports/balances/${asAtDate}`
  );
    return result?.data?.data;
};

export const getBalancesReportAsCsv = async (asAtDate: string) => {
  const result = await AxiosPrivateFirebaseInstance.get(
    `/admin_actions/reports/balances/${asAtDate}`,
    { headers: { Accept: "text/csv" } }
  );
    return result?.data;
};
