import { FC, useState } from "react";
import {
  Form,
  Button,
  Drawer,
  Input,
  Typography,
  message,
} from "antd";
import { useHistory } from "react-router-dom"; // Import useHistory from react-router-dom

import "antd/dist/antd.css";

import { Firebase } from "services";

const { Title } = Typography;

interface OwnProps {
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const EditOnboardingRecord: FC<OwnProps> = ({
  isVisible,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [recipientName, setRecipientName] = useState<string | null>(null);
  const [bankName, setBankName] = useState<string | null>(null);

  const history = useHistory(); // Initialize the history object

  const form = Form.useForm()[0];

  const createMasterRecipient = async () => {
    setIsLoading(true);

    try {
      if (!recipientName && !bankName) {
        message.error("You need to provide either a recipient name or bank name to create a MASTER Recipient");
        setIsLoading(false);
        return;
      }
      const id = await Firebase.createMasterRecipient({
        recipientName, 
        bankName
      });

      onClose();

      // Send the user to the newly created recipient
      history.push(`/app/recipient-detail/${id}`);
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={`Comment on and Resolve a Sanction Check`}
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={async ({ fileUpload, ...values }) => {
          // submit logic here
          createMasterRecipient();
        }}
      >
        <Title level={5}>Recipient Name</Title>
        <Input value={recipientName} onChange={(e) => setRecipientName(e.target.value)} />

        <Title level={5}>Bank Name</Title>
        <Input value={bankName} onChange={(e) => setBankName(e.target.value)} />

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>

    </Drawer>
  );
};

export default EditOnboardingRecord;
