import { FC, useState, useEffect, useRef } from "react";
import { Button, Form, message, Modal, Table, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import { openQuery, TCustomRouteComponentProps } from "utils";
import "./Fees.css";
import { usePrevious } from "hooks";

import db from "services/firestore";

const subscribeToFees = (callback) => {
  try {
    const unsubscribe = db
      .collection("referenceData")
      .doc("collections")
      .collection("fees")
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const Fees: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [fees, setFees] = useState([]);
  const [selectedFee, setSelectedFee] = useState(null);
  const prevSelectedFee = usePrevious(selectedFee);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [jsonValue, setJsonValue] = useState<any>({
    id: "CAD",
    swiftOurs: {
      HK: 25,
      TW: 35,
      RU: 15,
      BH: 25,
      JP: 35,
      UA: 12,
      NZ: 15,
      SG: 25,
      MC: 35,
      KE: 35,
      ID: 35,
      US: 12,
      CA: 12,
      IL: 35,
      AE: 25,
      IN: 15,
      CH: 12,
      ZA: 25,
      KR: 35,
      CL: 35,
      MX: 15,
      CN: 25,
      MY: 25,
      CR: 25,
      AU: 15,
      PE: 25,
      JE: 12,
      TR: 35,
    },
    swiftShared: 6,
  });

  const editEditorRef = useRef<Editor>(null);

  useEffect(() => {
    if (!prevSelectedFee) {
      editEditorRef.current?.jsonEditor.set(selectedFee);
    }
  }, [selectedFee, prevSelectedFee]);

  useEffect(() => {
    const unsubscribe = subscribeToFees((data) => setFees(data));

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const addNewFee = async ({ id, ...values }) => {
    try {
      await db
        .collection("referenceData")
        .doc("collections")
        .collection("fees")
        .doc(id)
        .set({ ...values });

      message.success("New fee was added.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateFee = async ({ id, ...rest }) => {
    try {
      await db
        .collection("referenceData")
        .doc("collections")
        .collection("fees")
        .doc(id)
        .update({ ...rest });

      message.success("Fee was updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const feesColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Swift Shared",
      dataIndex: "swiftShared",
      key: "swiftShared",
    },
    ...(allowWrite
      ? [
          {
            title: "",
            dataIndex: "",
            key: "toggleEnabledState",
            render: (record) => (
              <Button
                onClick={() => {
                  setSelectedFee(record);
                  setIsShowEditModal(true);
                }}
                type="primary"
              >
                Edit
              </Button>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <Title>Fees reference data</Title>

      {allowWrite && (
        <Button
          type="primary"
          style={{
            margin: "16px 0",
          }}
          onClick={() => setIsShowAddModal(!isShowAddModal)}
        >
          + Add fee
        </Button>
      )}

      <Table columns={feesColumns} dataSource={fees} />

      <Modal
        title="Add new fee"
        centered
        visible={isShowAddModal}
        onCancel={() => setIsShowAddModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            await addNewFee(jsonValue);
            setIsShowAddModal(false);
          }}
        >
          <Form.Item label="Fee data">
            <Editor
              ace={ace}
              theme="ace/theme/github"
              value={jsonValue}
              mode="code"
              onChange={(data) => {
                setJsonValue(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title="Edit fee"
        centered
        visible={isShowEditModal}
        onCancel={() => {
          setIsShowEditModal(false);
          setSelectedFee(null);
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            // @ts-ignore
            await updateFee(selectedFee);
            setIsShowEditModal(false);
            setSelectedFee(null);
          }}
        >
          <Form.Item label="Fee data">
            <Editor
              ref={editEditorRef}
              ace={ace}
              theme="ace/theme/github"
              value={{}}
              mode="code"
              onChange={(data) => {
                setSelectedFee(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default Fees;
