import { FC, useState, useEffect } from "react";
import { Table, Typography } from "antd";
import dayjs from "dayjs";

import "./House.css";
import {
  openDoc,
  openQuery,
  openQueryWithTimestamp,
  TCustomRouteComponentProps,
} from "utils";

import db from "services/firestore";
import Balances from "components/Balances/Balances";
import InternalTransfers from "components/InternalTransfers/InternalTransfers";
import InternalTransfer from "pages/EntityDetails/InternalTransfer";

const subscribeToEntity = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntityTransactions = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .collection("transactions")
      .orderBy("_created", "desc")
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntityFundingEvents = (entity, callback) => {
  try {
    const unsubscribe = db
      .collection("eventsFromCc")
      .where("_event", "==", "funding")
      .where("account_id", "==", entity.externalRefs.ccId)
      .orderBy("_created", "desc")
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntityOtherEvents = (entity, callback) => {
  try {
    const unsubscribe = db
      .collection("eventsFromCc")
      .where("_event", "!=", "funding")
      .where("account_id", "==", entity.externalRefs.ccId)
      .orderBy("_created", "desc")
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const transactionsColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Direction",
    dataIndex: "direction",
    key: "direction",
  },
  {
    title: "Matched",
    dataIndex: "matched",
    key: "matched",
    render: (val) => (val ? "TRUE" : "FALSE"),
  },
  {
    title: "Matched with",
    dataIndex: "matchedWith",
    key: "matchedWith",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
];

const eventsColumns = [
  {
    title: "Created",
    dataIndex: "created_at",
    key: "created_at",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
];

const EntityOverview: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [fundingEvents, setFundingEvents] = useState([]);
  const [otherEvents, setOtherEvents] = useState([]);
  const [showInternalTransferForm, setShowInternalTransferForm] =
    useState(false);

  useEffect(() => {
    const unsubscribeEntity = subscribeToEntity("house", (data) => {
      setEntity(data);
    });

    const unsubscribeTransactions = subscribeToEntityTransactions(
      "house",
      (data) => setTransactions(data)
    );

    return () => {
      if (unsubscribeEntity) {
        unsubscribeEntity();
      }

      if (unsubscribeTransactions) {
        unsubscribeTransactions();
      }
    };
  }, []);

  useEffect(() => {
    if (entity) {
      const unsubscribe = subscribeToEntityFundingEvents(entity, (data) =>
        setFundingEvents(data)
      );

      const unsubscribeOtherEvents = subscribeToEntityOtherEvents(
        entity,
        (data) => setOtherEvents(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }

        if (unsubscribeOtherEvents) {
          unsubscribeOtherEvents();
        }
      };
    }
  }, [entity]);

  return (
    <div>
      {entity && (
        <>
          <Title>{entity.name}</Title>
          <span style={{ display: "inline-block" }}>
            <i>
              CC IDs:{" "}
              {`${entity.externalRefs.ccId} (${entity.externalRefs.ccShortReference})`}
            </i>
          </span>

          {showInternalTransferForm && (
            <InternalTransfer
              entity={entity}
              onClose={() => setShowInternalTransferForm(false)}
              isVisible={!!showInternalTransferForm}
            />
          )}
        </>
      )}

      <br />
      <br />

      <Balances entityId="house" />

      <InternalTransfers
        entityId="house"
        allowWrite={allowWrite}
        setShowInternalTransferForm={setShowInternalTransferForm}
      />

      <Table
        title={() => <Title level={2}>Funding Matching Transactions</Title>}
        columns={transactionsColumns}
        dataSource={transactions}
      />

      <Table
        title={() => <Title level={2}>Funding events</Title>}
        columns={eventsColumns}
        dataSource={fundingEvents}
      />

      <Table
        title={() => <Title level={2}>Other events</Title>}
        columns={eventsColumns}
        dataSource={otherEvents}
      />
    </div>
  );
};

export default EntityOverview;
