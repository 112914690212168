import { FC, useState } from "react";
import { Row, Switch, Typography } from "antd";
import { IAdminUser } from "types";
import { IUpdateAdminUserRoles } from "services/firebase";
const { Paragraph } = Typography;

const defaultRoles: IAdminUser["customClaims"] = {
  owner: false,
  admin: false,
  itadmin: false,
  compliance: false,
};

interface IOwnProps {
  user: IAdminUser;
  isLoading: boolean;
  allowWrite: boolean;
  updateAdminUserRolesHandler: (
    payload: IUpdateAdminUserRoles
  ) => Promise<void>;
}

const RolePickerCell: FC<IOwnProps> = ({
  user,
  isLoading,
  allowWrite,
  updateAdminUserRolesHandler,
}) => {
  const [roles, setRoles] = useState<IAdminUser["customClaims"]>({
    ...defaultRoles,
    ...user.customClaims,
  });

  const onChange = (data: IUpdateAdminUserRoles) => {
    setRoles(data.roles);
    updateAdminUserRolesHandler(data);
  };

  return (
    <Row align="middle">
      {Object.keys(roles).map((role) => {
        const isChecked = roles?.[role];

        return (
          <Row
            style={{ margin: "0 12px 0 0" }}
            justify="space-between"
            align="middle"
          >
            <Paragraph style={{ margin: "0 12px 0 0" }}>{role}</Paragraph>
            {!allowWrite && <Paragraph>{isChecked ? 'YES' : 'NO'}</Paragraph>}
            {allowWrite && (
              <Switch
                disabled={isLoading}
                checked={isChecked}
                onChange={() =>
                  onChange({
                    roles: { ...roles, [role]: !isChecked },
                    userId: user.uid,
                  })
                }
              />
            )}
          </Row>
        );
      })}
    </Row>
  );
};

export default RolePickerCell;
