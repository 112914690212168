import { message } from "antd";
import { AxiosPrivateFirebaseInstance } from "settings";
import { Notify } from "utils";
import { IEntityUser, IResponse, TEntityPackages } from "types";

export const actOnBehalfOfEntity = async (entityId: string) => {
  await AxiosPrivateFirebaseInstance.post(
    `/admin_actions/actonbehalfof/${entityId}`
  ).then((res) => {
    message.success("You can now log into HedgeFlows as this entity.");

    const webUrl =
      process.env.REACT_APP_WEB_URL || "https://app.hedgeflows.com";

    setTimeout(() => {
      window.open(`${webUrl}/app/dashboard`, "_blank");
    }, 2000);

    return true;
  });
};

export const runBacktester = async (entityId: string) => {
  await AxiosPrivateFirebaseInstance.get(
    `/analysis/backtester-data/${entityId}`
  )
    .then((res) => {
      message.success("Backtester calculations have been kicked off.");
      return true;
    })
    .catch((err) => {
      message.error(err.message);
      return false;
    });
};

export const getProfitAndLossReport = async ({
  entityId,
  from,
  to,
}: {
  entityId: string;
  from: string;
  to: string;
}) => {
  try {
    var query = new URLSearchParams({
      from,
      to,
    });
    const result = await AxiosPrivateFirebaseInstance.get(
      `admin_actions/integrations/${entityId}/profitAndLoss?${query.toString()}`
    );
    return result;
  } catch (error) {
    Notify.error(error.message);
  }
};

const ACCOUNTING_SYSTEMS = [
  "xero",
  "quickbooks",
  "dynamics",
  "netsuite",
  "sap",
] as const;
type TAccountingSystem = (typeof ACCOUNTING_SYSTEMS)[number];

type TAccountingIntegrationConnectionStatus =
  | "notConnected"
  | "connected"
  | "disconnected"
  | "reauthenticate"
  | "tenantSelection";

export interface IIntegrationBase {
  system: TAccountingSystem;
  environmentName: string | undefined;
  status: TAccountingIntegrationConnectionStatus;
  _created: any;
  _updated: any;
  accessCodeExpiryTimestamp: string | undefined;
}

export const getIntegrations = async (entityId: string) => {
  const integrations: IIntegrationBase[] =
    await AxiosPrivateFirebaseInstance.get(
      `/admin_actions/integrations/${entityId}`
    )
      .then((res: any) => {
        if (res.data.success) {
          return res.data.data as any[];
        } else {
          message.error(res.message);
          return [];
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message);
        return [];
      });

  return integrations;
};

export const getIntegrationSettings = async (entityId: string) => {
  const integrations: IIntegrationBase[] =
    await AxiosPrivateFirebaseInstance.get(
      `/admin_actions/integrations/${entityId}/settings`
    )
      .then((res: any) => {
        if (res.data.success) {
          return res.data.data as any[];
        } else {
          message.error(res.message);
          return [];
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message);
        return [];
      });

  return integrations;
};

export const reloadIntegrationData = async (entityId: string) => {
  await AxiosPrivateFirebaseInstance.post(
    `/admin_actions/integrations/${entityId}/reloaddata`
  )
    .then((res: any) => {
      if (res?.data?.success) {
        message.success("Data reloaded");
      } else {
        message.error(res?.data?.error);
      }
    })
    .catch((err) => {
      console.log(err);
      message.error(err.message);
    });
};

interface IEntityOnboardingUpdate {
  creditLimit?: number;
  approvedQuarterlyFx?: number;
  comments?: string;
  rating?: number;
  ddType?: string;
  conversionFeeRateOverride?: number;
  shouldShowReports?: boolean;
  consumerDutyApplicable?: boolean;
  subStatusAfterOnboarding?: string;
}

export const updateEntityOnboardingCompanyDetails = async (
  entityId: string,
  updatedValues: IEntityOnboardingUpdate //Record<string, any>
) => {
  const updateObject: IEntityOnboardingUpdate = {};

  if (updatedValues.creditLimit) {
    const creditLimit = Number(updatedValues.creditLimit);
    if (isNaN(creditLimit)) {
      throw new Error(`Credit Limit has to be a number`);
    }
    updateObject.creditLimit = creditLimit;
  }

  if (updatedValues.approvedQuarterlyFx) {
    const approvedQuarterlyFx = Number(updatedValues.approvedQuarterlyFx);
    if (isNaN(approvedQuarterlyFx)) {
      throw new Error(`Approved Quarterly Max has to be a number`);
    }
    updateObject.approvedQuarterlyFx = approvedQuarterlyFx;
  }

  if (updatedValues.conversionFeeRateOverride) {
    const conversionFeeRateOverride = Number(
      updatedValues.conversionFeeRateOverride
    );
    if (isNaN(conversionFeeRateOverride)) {
      throw new Error(`Conversion Fee Rate Override has to be a number`);
    }
    updateObject.conversionFeeRateOverride = conversionFeeRateOverride;
  } else if (updatedValues.conversionFeeRateOverride !== undefined) {
    updateObject.conversionFeeRateOverride = null;
  }

  if (updatedValues.rating) {
    const rating = Number(updatedValues.rating);
    if (isNaN(rating)) {
      throw new Error(`Rating has to be a number`);
    }
    updateObject.rating = rating;
  }
  if (updatedValues.comments) {
    updateObject.comments = updatedValues.comments;
  }
  if (updatedValues.ddType) {
    updateObject.ddType = updatedValues.ddType;
  }

  updateObject.shouldShowReports = !!updatedValues.shouldShowReports;
  updateObject.consumerDutyApplicable = !!updatedValues.consumerDutyApplicable;

  if (updatedValues.subStatusAfterOnboarding) {
    updateObject.subStatusAfterOnboarding =
      updatedValues.subStatusAfterOnboarding;
  }

  console.log("updating entity");
  await AxiosPrivateFirebaseInstance.put(
    `/admin_actions/entity_details/${entityId}`,
    updateObject
  ).catch((error) => {
    console.log(error);
    throw error;
  });
};

interface IEntityPackagesProps {
  globalFreeTrial?: string;
  automationFreeTrial?: string;
  fxManagementFreeTrial?: string;
  globalEnabledUntil?: string;
  automationEnabledUntil?: string;
  fxManagementEnabledUntil?: string;
}

export const updateEntityPackages = async (
  entityId: string,
  updatedValues: IEntityPackagesProps
) => {
  const updateObject: TEntityPackages = {
    global: {
      freeTrialUntil: updatedValues.globalFreeTrial || null,
      enabledUntil: updatedValues.globalEnabledUntil || null,
    },
    automation: {
      freeTrialUntil: updatedValues.automationFreeTrial || null,
      enabledUntil: updatedValues.automationEnabledUntil || null,
    },
    fxManagement: {
      freeTrialUntil: updatedValues.fxManagementFreeTrial || null,
      enabledUntil: updatedValues.fxManagementEnabledUntil || null,
    },
  };

  await AxiosPrivateFirebaseInstance.put(
    `/admin_actions/entity_packages/${entityId}`,
    updateObject
  ).catch((error) => {
    Notify.error(error.message);
  });
};

export const getEntityUsers = async ({ entityId }: { entityId: string }) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IEntityUser[]>>(
    `entities/${entityId}/users`
  );

export const approveInviteAndSetUserRoles = async (inviteId: string) =>
  AxiosPrivateFirebaseInstance.post(
    `/admin_actions/invites/${inviteId}/approve`
  );
