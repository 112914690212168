import { AxiosPrivateFirebaseInstance } from "settings";

export const getBalancesAndTransactions = async (entityId: string, onTheFly = false) => {
  const result = await AxiosPrivateFirebaseInstance.get(
    `/admin_actions/${entityId}/wallet${onTheFly ? "?on_the_fly=true" : ""}`
  );

  return result?.data?.data;
};

export const regenerateWallet = async (entityId: string) => {
  const result = await AxiosPrivateFirebaseInstance.post(
    `/admin_actions/${entityId}/wallet/regenerate`
  );

  const success = result?.status === 200 && result?.data?.success;
  
  return { success, message: result?.data?.message };
};
