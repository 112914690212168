import { createStore, createTypedHooks } from "easy-peasy";

import { UserStateModel, UserState } from "./user";
import { RateContractsState, RateContractsStateModel } from "./rateContracts";
import { TransfersState, TransfersStateModel } from "./transfers";
import { CurrenciesState, CurrenciesStateModel } from "./currencies";
import { ReferenceDataState, ReferenceDataStateModel } from "./referenceData";
import { RecipientsState, RecipientsStateModel } from "./recipients";

const typedHooks = createTypedHooks<StateModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export interface StateModel {
  UserState: UserStateModel;
  RateContractsState: RateContractsStateModel;
  TransfersState: TransfersStateModel;
  CurrenciesState: CurrenciesStateModel;
  ReferenceDataState: ReferenceDataStateModel;
  RecipientsState: RecipientsStateModel;
}

export const stateModel: StateModel = {
  UserState,
  RateContractsState,
  TransfersState,
  CurrenciesState,
  ReferenceDataState,
  RecipientsState,
};

const store = createStore(stateModel);

export default store;
