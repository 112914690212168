import { action, Action, thunk, Thunk } from 'easy-peasy';

import { Firebase } from 'services';
import { CheckRecipientBankDetailsPayload } from 'services/firebase';
import { IRecipient } from 'types';
import { Notify } from 'utils';

export interface RecipientsStateModel {
  recipients: IRecipient[];
  setState: Action<RecipientsStateModel, [string, any]>;
  subscribeToRecipients: Thunk<
    RecipientsStateModel,
    Omit<Firebase.SubscribeToRecipientsParams, 'callback'>,
    null,
    null,
    () => void
  >;
  createRecipient: Thunk<RecipientsStateModel, IRecipient>;
  updateRecipient: Thunk<RecipientsStateModel, Firebase.UpdateRecipientParams>;
  deleteRecipient: Thunk<RecipientsStateModel, IRecipient['id']>;
  checkRecipientBankDetails: Thunk<
    RecipientsStateModel,
    CheckRecipientBankDetailsPayload
  >;
}

export const RecipientsState: RecipientsStateModel = {
  recipients: [],
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  subscribeToRecipients: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToRecipients({
      ...payload,
      callback: (recipients) => setState(['recipients', recipients]),
    });

    return subscriber;
  }),
  createRecipient: thunk(async (actions, payload) => {
    const data = await Firebase.createRecipient(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to create recipient');
    }
  }),
  updateRecipient: thunk(async (actions, payload) => {
    const data = await Firebase.updateRecipient(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to update recipient');
    }
  }),
  deleteRecipient: thunk(async (actions, payload) => {
    const data = await Firebase.deleteRecipient(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to delete recipient');
    }
  }),
  checkRecipientBankDetails: thunk(async (actions, payload) => {
    try {
      const { data } = await Firebase.checkRecipientBankDetails(payload);

      return data;
    } catch (error) {
      Notify.error(error.message);
    }
  }),
};
