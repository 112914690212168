import { FC, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Row,
} from "antd";

import "antd/dist/antd.css";

import { Firebase } from "services";
import { allowedLedgerAccountNames, TLedgerAccountNames } from "types";
import { roundToPrecision } from "utils";

const { Option } = Select;

interface OwnProps {
  entityId: string;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const CreateLedgerTransaction: FC<OwnProps> = ({
  isVisible,
  entityId,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const form = Form.useForm()[0];

  const onCreateLedgerTransaction = async ({
    from,
    to,
    currency,
    amount,
    description,
    transactionTimestamp,
  }: {
    from: string;
    to: string;
    currency: string;
    amount: number;
    description: string;
    transactionTimestamp?: string;
  }) => {
    setIsLoading(true);

    if (!from || !to || !currency || !amount || !description) {
      message.error(
        "Please fill in all the required fields, which are From Account, To Account, Currency, Amount and Description"
      );
      setIsLoading(false);
      return;
    }

    try {
      const response = await Firebase.createLedgerTransaction({
        ledger: entityId,
        postings: [
          {
            amount: roundToPrecision(amount),
            currency: currency.toUpperCase(),
            from: from.toLowerCase() as TLedgerAccountNames,
            to: to.toLowerCase() as TLedgerAccountNames,
          },
        ],
        description,
        transactionTimestamp,
      });

      if (response.data.success) {
        message.success(
          `Created a Ledger Transaction with ID ${response.data.id} successfully`
        );
        form.resetFields();
        onClose();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={`Manually Create a Ledger Transaction`}
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={async ({ fileUpload, ...values }) => {
          // submit logic here
          onCreateLedgerTransaction(values as any);
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="from"
              label="From Account"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select placeholder="From">
                {allowedLedgerAccountNames.map((account) => (
                  <Option key={account} value={account}>
                    {account}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="to"
              label="To Account"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select placeholder="To">
                {allowedLedgerAccountNames.map((account) => (
                  <Option key={account} value={account}>
                    {account}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="currency"
              label="Currency"
              rules={[
                {
                  required: true,
                  len: 3,
                  message: "Has to be a currency code",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                { required: true, message: "Required" },
                {
                  validator: (rule, value) =>
                    value > 0
                      ? Promise.resolve()
                      : Promise.reject("Amount has to be greater than 0"),
                },
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) =>
                  value?.replace(/\$\s?|(,*)/g, "") as unknown as number
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input style={{ width: "100%" }} placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Transacted on (optional)"
              name="transactionTimestamp"
              style={{ marginBottom: "6px" }}
            >
              <DatePicker
                showTime={{ format: "HH:mm:ss" }}
                format="DD MMM YYYY HH:mm:ss"
                placeholder="Transacted on (optional)"
              />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateLedgerTransaction;
