import "./App.css";
import { Layout } from "containers";
import useAuth from "hooks/useAuth";
import { useBootstrap } from "hooks";

const App = () => {
  useAuth();
  useBootstrap();

  return <Layout />;
};

export default App;
