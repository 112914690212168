import { FC, useState, useEffect } from "react";
import { Button, message, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import db from "services/firestore";
import { subscribeToPageAccess } from "services/firebase";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import "./AdminPageAccess.css";
import { TCustomRouteComponentProps } from "utils";

const { Title } = Typography;

const AdminPageAccess: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [pageAccess, setPageAccess] = useState(null);

  useEffect(() => {
    const unsubscribe = subscribeToPageAccess((data) => setPageAccess(data));

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const updatePageAccess = async () => {
    try {
      await db
        .collection("referenceData")
        .doc("roleBasedAdminPageAccess")
        .update(pageAccess);

      message.success("Page access were updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <div>
      <Title>Page Access</Title>

      {pageAccess && (
        <>
          <Editor
            ace={ace}
            theme="ace/theme/github"
            value={pageAccess}
            mode="code"
            onChange={(data) => {
              if (!data) {
                setPageAccess({});
                return;
              }
              setPageAccess(data);
            }}
          />

          {allowWrite && (
            <Button
              style={{
                margin: "16px 0",
              }}
              type="primary"
              onClick={updatePageAccess}
            >
              Update
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default AdminPageAccess;
