import {
  IRecipient,
  IResponse,
  ISanctionResultUpdate,
  RECIPIENT_STATUS,
} from "types";
import { getUniqueDecreasingNumber, openQuery } from "utils";
import { AxiosPrivateFirebaseInstance } from "settings";
import { Firebase } from "services";
import firebase from "firebase/app";

import db from "services/firestore";

export interface SubscribeToRecipientsParams {
  entityId: string;
  callback: (recipients: IRecipient[]) => void;
}

export const subscribeToRecipients = ({
  entityId,
  callback,
}: SubscribeToRecipientsParams) => {
  try {
    return db
      .collection("recipients")
      .where("_owner", "==", entityId)
      .onSnapshot((query) => callback(openQuery(query)));
  } catch (error) {
    console.warn(error);
  }
};

export const createRecipient = async (params: IRecipient) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post(
      `/recipients`,
      params
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};

export interface UpdateRecipientParams {
  recipientId: IRecipient["id"];
  recipientData: Omit<IRecipient, "id">;
}

export const updateRecipient = async ({
  recipientId,
  recipientData,
}: UpdateRecipientParams) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.put(
      `/recipients/${recipientId}`,
      recipientData
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const deleteRecipient = async (recipientId: IRecipient["id"]) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.delete(
      `/recipients/${recipientId}`
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};

export interface CheckRecipientBankDetailsPayload {
  type: "iban" | "bic_swift";
  value: string;
}

export const checkRecipientBankDetails = async (
  payload: CheckRecipientBankDetailsPayload
) => {
  return await AxiosPrivateFirebaseInstance.post<IResponse>(
    "/recipients/bank_details",
    payload
  );
};

export interface UpdateRecipientOnboardingRecordParams {
  recipientId: string;
  onboardingRecordId: string;
  updateData: ISanctionResultUpdate;
}

export const updateRecipientOnboardingRecord = async ({
  recipientId,
  onboardingRecordId,
  updateData,
}: UpdateRecipientOnboardingRecordParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse>(
    `/admin_actions/recipients/${recipientId}/onboardings/${onboardingRecordId}`,
    updateData
  );

export const createMasterRecipient = async ({
  recipientName,
  bankName,
}: {
  recipientName?: string;
  bankName?: string;
}) => {
  if (
    (!recipientName || recipientName.length === 0) &&
    (!bankName || bankName.length === 0)
  ) {
    throw new Error("Recipient name or bank name is required");
  }

  const recipientToCreate: Partial<IRecipient> = {
    _owner: "house",
    _created: firebase.firestore.FieldValue.serverTimestamp(),
    _createdBy: Firebase.auth.currentUser.uid,
    status: RECIPIENT_STATUS.initiated,
  };
  if (recipientName && recipientName.length > 0) {
    recipientToCreate.recipientName = recipientName;
  } else {
    recipientToCreate.recipientName = `MASTER Recipient for ${bankName}`;
  }
  if (bankName && bankName.length > 0) {
    recipientToCreate.bankName = bankName;
  } else {
    recipientToCreate.bankName = "n/a";
  }

  const docId = getUniqueDecreasingNumber("R");

  await db.collection("recipients").doc(docId).set(recipientToCreate);

  return docId;
};

export const updateRecipientCheck = async ({
  recipientId,
  checkId,
  updateData,
}: {
  recipientId: string;
  checkId: string;
  updateData: {
    comments?: string;
    isResolved: boolean;
  };
}) =>
  AxiosPrivateFirebaseInstance.put<IResponse<void>>(
    `/admin_actions/recipients/${recipientId}/checks/${checkId}`,
    updateData
  );
