import { FC, useState, useEffect } from "react";
import { Badge, Button, Descriptions, Table, Typography } from "antd";

import dayjs from "dayjs";
import "./BulkPayments.css";
import {
  toCurrencyNumber,
  openDoc,
  openDocWithTimestamp,
  openQueryWithTimestamp,
  TCustomRouteComponentProps,
} from "utils";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import db from "services/firestore";
import {
  TRANSFER_STATUS,
  IEntity,
  ITransfer,
  IBulkPayment,
  BULK_PAYMENT_STATUS,
} from "types";
import EditBulkPayment from "./components/EditBulkPayment";

const subscribeToBulkPayment = (bulkPaymentId: string, callback) => {
  try {
    const unsubscribe = db
      .collection("bulkPayments")
      .doc(bulkPaymentId)
      .onSnapshot((doc) =>
        callback(
          openDocWithTimestamp(doc, ["_created", "_updated", "fundedTimestamp"])
        )
      );
    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToTransfers = (bulkPaymentId: string, callback) => {
  try {
    const unsubscribe = db
      .collection("transfers")
      .where("bulkPaymentId", "==", bulkPaymentId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntity = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const Transfer: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null as IEntity);
  const [bulkPayment, setBulkPayment] = useState(null as IBulkPayment);
  const [transfers, setTransfers] = useState([]);
  const [modifyBulkPayment, setModifyBulkPayment] = useState(false);

  // @ts-ignore
  const { bulk_payment_id } = useParams();

  const transfersColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Sell",
      dataIndex: "sell",
      key: "sell",
      render: (text, record: ITransfer) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.sellAmount)}&nbsp;
            <b>{record.sellCurrency}</b>
          </div>
        );
      },
    },
    {
      title: "Buy",
      dataIndex: "buy",
      key: "buy",
      render: (text, record: ITransfer) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.buyAmount)}&nbsp;
            <b>{record.buyCurrency}</b>
          </div>
        );
      },
    },
    {
      title: "Funding required",
      dataIndex: "payAmount",
      key: "payAmount",
      render: (text, record: ITransfer) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.payAmount)}&nbsp;
            <b>{record.sellCurrency}</b>
          </div>
        );
      },
    },
    {
      title: "Pay by (GMT)",
      dataIndex: "status",
      key: "status",
      render: (text, record: ITransfer) =>
        `${record.payByDate} ${record.payByTimeGMT}`,
    },
    {
      title: "Scheduled on",
      dataIndex: "scheduledPaymentDate",
      key: "scheduledPaymentDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record: ITransfer) => {
        let status = record.status.toString();
        status === TRANSFER_STATUS.awaitingPayment &&
          (status = "Awaiting Payment");
        status === TRANSFER_STATUS.paymentSent && (status = "Sent");
        return <>{status}</>;
      },
    },
    {
      title: "Recipient ID",
      dataIndex: "recipientId",
      key: "recipientId",
      render: (text, record: ITransfer) => {
        return (
          <span>
            <Link to={"/app/recipient-detail/" + record.recipientId}>
              {text}
            </Link>
          </span>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/transfer/" + record.id}>{text}</Link>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (bulk_payment_id) {
      const unsubscribeBulkPayment = subscribeToBulkPayment(
        bulk_payment_id,
        (data) => setBulkPayment(data)
      );

      const unsubscribeTransfers = subscribeToTransfers(
        bulk_payment_id,
        (data) => setTransfers(data)
      );

      return () => {
        unsubscribeBulkPayment?.();
        unsubscribeTransfers?.();
      };
    }
  }, [bulk_payment_id]);

  useEffect(() => {
    if (bulkPayment) {
      const unsubscribe = subscribeToEntity(bulkPayment._owner, (data) =>
        setEntity(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [bulkPayment]);

  return (
    <div>
      <>
        <EditBulkPayment
          bulkPayment={bulkPayment}
          isVisible={modifyBulkPayment}
          onClose={() => setModifyBulkPayment(false)}
        />
      </>
      <>
        {entity && <Title>Bulk Payment Details</Title>}

        <p>
          {bulkPayment &&
            `${bulkPayment.id} (Payment Run: ${bulkPayment.paymentRunId})`}{" "}
          <br />
          {entity && (
            <>
              For{" "}
              <Link to={"/app/entity-detail/" + entity.id}>{entity.name}</Link>
            </>
          )}
        </p>
        {bulkPayment && (
          <Descriptions title="Bulk Payment Details" bordered>
            {bulkPayment.buyAmountsBreakdown.map((breakdown, index) => (
              <>
                <Descriptions.Item label={`Buy Amount ${index + 1}`}>
                  {breakdown.currency} {toCurrencyNumber(breakdown.amount)}
                </Descriptions.Item>
                <Descriptions.Item label={`Rate ${index + 1}`}>
                  {breakdown.rate}
                </Descriptions.Item>
                <Descriptions.Item label={`Sell Amount ${index + 1}`}>
                  {bulkPayment.sellCurrency}{" "}
                  {toCurrencyNumber(breakdown.sellAmount)}
                </Descriptions.Item>
              </>
            ))}

            <Descriptions.Item label="Total Sell Amount">
              {bulkPayment.sellCurrency}{" "}
              {toCurrencyNumber(bulkPayment.sellAmount)}
            </Descriptions.Item>
            <Descriptions.Item label="">
              <></>
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {dayjs(bulkPayment._created).format("YYYY-MM-DD H:mm")}
            </Descriptions.Item>

            <Descriptions.Item label="Payment Fee">
              {bulkPayment.sellCurrency}{" "}
              {toCurrencyNumber(bulkPayment.paymentFee)}
            </Descriptions.Item>
            <Descriptions.Item label="">
              <></>
            </Descriptions.Item>
            <Descriptions.Item label="Updated">
              {bulkPayment._updated &&
                dayjs(bulkPayment._updated).format("YYYY-MM-DD H:mm")}
            </Descriptions.Item>

            <Descriptions.Item label="Funding required">
              {bulkPayment.sellCurrency}{" "}
              {toCurrencyNumber(bulkPayment.payAmount)}
            </Descriptions.Item>
            <Descriptions.Item label="Fund By Date Time">
              {bulkPayment.payByDate} {bulkPayment.payByTimeGMT}
            </Descriptions.Item>
            <Descriptions.Item label="Funded">
              {bulkPayment.fundedTimestamp &&
                dayjs(bulkPayment.fundedTimestamp).format("YYYY-MM-DD")}
            </Descriptions.Item>

            <Descriptions.Item label="Status">
              {bulkPayment.status === BULK_PAYMENT_STATUS.awaitingPayment && (
                <Badge status="warning" text="Awaiting Funding" />
              )}
              {bulkPayment.status === BULK_PAYMENT_STATUS.fundingInitiated && (
                <Badge status="processing" text="Funding Initiated" />
              )}
              {bulkPayment.status === BULK_PAYMENT_STATUS.funded && (
                <Badge status="processing" text="Funded" />
              )}
              {bulkPayment.status === BULK_PAYMENT_STATUS.processing && (
                <Badge status="processing" text="Processing" />
              )}
              {bulkPayment.status === BULK_PAYMENT_STATUS.completed && (
                <Badge status="success" text="Sent" />
              )}
              {bulkPayment.status === BULK_PAYMENT_STATUS.cancelled && (
                <Badge status="error" text="Cancelled" />
              )}
              {bulkPayment.status === BULK_PAYMENT_STATUS.transfersFailed && (
                <Badge status="error" text="Transfers Have Failed" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Scheduled Payment Date">
              {bulkPayment.scheduledPaymentDate}
            </Descriptions.Item>
            <Descriptions.Item label="">
              {allowWrite && (
                <Button
                  type="primary"
                  style={{ float: "right" }}
                  onClick={() => setModifyBulkPayment(true)}
                >
                  Modify
                </Button>
              )}
            </Descriptions.Item>
          </Descriptions>
        )}

        <Table
          title={() => <Title level={2}>Transfers</Title>}
          columns={transfersColumns}
          dataSource={transfers}
        />
      </>
    </div>
  );
};

export default Transfer;
