import { FC, useState, useEffect } from "react";
import { Button, message, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import "./NaturesOfBusiness.css";

import db from "services/firestore";
import { TCustomRouteComponentProps } from "utils";

const subscribeToNatureOfBusiness = (callback) => {
  try {
    const unsubscribe = db
      .collection("referenceData")
      .doc("natureOfBusiness")
      .onSnapshot((doc) => callback(doc.data()));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const NaturesOfBusiness: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [naturesOfBusiness, setNaturesOfBusiness] = useState(null);

  useEffect(() => {
    const unsubscribe = subscribeToNatureOfBusiness((data) =>
      setNaturesOfBusiness(data)
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const updateNaturesOfBusiness = async () => {
    try {
      await db
        .collection("referenceData")
        .doc("natureOfBusiness")
        .update(naturesOfBusiness);

      message.success("Natures of business were updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  return naturesOfBusiness ? (
    <div>
      <Title>Natures of business reference data</Title>

      <Editor
        ace={ace}
        theme="ace/theme/github"
        value={naturesOfBusiness}
        mode="code"
        onChange={(value) => {
          if (!value) {
            setNaturesOfBusiness({});
            return;
          }
          setNaturesOfBusiness(value);
        }}
      />

      {allowWrite && (
        <Button
          style={{
            margin: "16px 0",
          }}
          type="primary"
          onClick={updateNaturesOfBusiness}
        >
          Update
        </Button>
      )}
    </div>
  ) : null;
};

export default NaturesOfBusiness;
