import { FC, useEffect, useState } from "react";
import { Table, Typography, Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { Firebase } from "services";
import { useParams } from "react-router";
import FundingStatus from 'components/FundingStatus/FundingStatus';
import DocViewer from "pages/Overview/components/DocViewer";

const { Title } = Typography;
const { Column } = Table;

const Funding: FC = () => {
  const [funding, setFunding] = useState([]);
  const [, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");
  const [docToView, setDocToView] = useState(null);

  // @ts-ignore
  const { funding_id } = useParams();
  
  useEffect(() => {
    const unsubscribe = Firebase.subscribeToFunding({
      callback: (data) => {
        if (funding_id) {
          const filteredData = data.filter((funding) => funding.id === funding_id);
          setFunding(filteredData)
        } else {
          setFunding(data)
        }
      },
    });

    return () => {
      unsubscribe && unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  return (
    <div>
      <DocViewer
        doc={docToView}
        isVisible={!!docToView}
        onClose={() => setDocToView(null)}
      />
      <Table title={() => <Title level={2}>Funding</Title>} dataSource={funding}>
        <Column
          title="Timestamp"
          key="timestamp"
          dataIndex="timestamp"
          render={(date) => dayjs(date).format("DD MMM YYYY HH:mm:ss")}
          {...getColumnSearchProps("timestamp")}
        />
        <Column
          title="Updated"
          key="_updated"
          dataIndex="_updated"
          render={(date) => dayjs(date).format("DD MMM YYYY HH:mm:ss")}
          {...getColumnSearchProps("_updated")}
        />
        <Column
          title="Currency"
          key="currency"
          dataIndex="currency"
          {...getColumnSearchProps("currency")}
        />
        <Column
          title="Amount"
          key="amount"
          dataIndex="amount"
          {...getColumnSearchProps("amount")}
        />
        <Column
          title="ID"
          key="id"
          dataIndex="id"
          render={(text, record) => (
            <span>
              <Link to="#" onClick={() => setDocToView(record)}>{text}</Link>
            </span>
          )}
          {...getColumnSearchProps("id")}
        />
        <Column
          title="Sender ID"
          key="senderId"
          dataIndex="senderId"
          render={(value) => (
            <Link to={`/app/sender-detail/${value}`}>{value}</Link>
          )}
          {...getColumnSearchProps("senderId")}
        />
        <Column
          title="Entity ID"
          key="_owner"
          dataIndex="_owner"
          render={(value) => (
            <Link to={`/app/entity-detail/${value}`}>{value}</Link>
          )}
          {...getColumnSearchProps("_owner")}
        />
        <Column
          title="Status"
          key="status"
          render={({ status, id }) => (
            <FundingStatus status={status} />
          )}
        />
       
      </Table>
    </div>
  );
};

export default Funding;
