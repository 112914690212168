import { FC, useState, useEffect, useRef } from "react";
import { Button, Form, message, Modal, Table, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import { openQuery, TCustomRouteComponentProps } from "utils";
import "./Currencies.css";
import { usePrevious } from "hooks";

import db from "services/firestore";

const subscribeToCurrencies = (callback) => {
  try {
    const unsubscribe = db
      .collection("referenceData")
      .doc("collections")
      .collection("currencies")
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const Currencies: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const prevSelectedCurrency = usePrevious(selectedCurrency);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [jsonValue, setJsonValue] = useState<any>({
    buy: false,
    sell: false,
    enabled: false,
    code: "GBP",
    country: "United Kingdom",
    countryCode: "gb",
    name: "British Pound",
    symbol: "£",
  });

  const editEditorRef = useRef<Editor>(null);

  useEffect(() => {
    if (!prevSelectedCurrency) {
      editEditorRef.current?.jsonEditor.set(selectedCurrency);
    }
  }, [selectedCurrency, prevSelectedCurrency]);

  useEffect(() => {
    const unsubscribe = subscribeToCurrencies((data) => setCurrencies(data));

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const addNewCurrency = async (values) => {
    try {
      await db
        .collection("referenceData")
        .doc("collections")
        .collection("currencies")
        .doc(values.code)
        .set({ ...values });

      message.success("New currency was added.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateCurrency = async ({ id, ...rest }) => {
    try {
      await db
        .collection("referenceData")
        .doc("collections")
        .collection("currencies")
        .doc(id)
        .update({ ...rest });

      message.success("Currency was updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const currenciesColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Country code",
      dataIndex: "countryCode",
      key: "countryCode",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Buy",
      dataIndex: "buy",
      key: "buy",
      render: (text) => {
        return <span>{`${text}`}</span>;
      },
    },
    {
      title: "Sell",
      dataIndex: "sell",
      key: "sell",
      render: (text) => {
        return <span>{`${text}`}</span>;
      },
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      render: (text) => {
        return <span>{`${text}`}</span>;
      },
    },
    ...(allowWrite
      ? [
          {
            title: "",
            dataIndex: "",
            key: "toggleEnabledState",
            render: (record) => (
              <Button
                onClick={() => {
                  setSelectedCurrency(record);
                  setIsShowEditModal(true);
                }}
                type="primary"
              >
                Edit
              </Button>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <Title>Currencies reference data</Title>

      {allowWrite && (
        <Button
          type="primary"
          style={{
            margin: "16px 0",
          }}
          onClick={() => setIsShowAddModal(!isShowAddModal)}
        >
          + Add currency
        </Button>
      )}

      <Table columns={currenciesColumns} dataSource={currencies} />

      <Modal
        title="Add new currency"
        centered
        visible={isShowAddModal}
        onCancel={() => setIsShowAddModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            await addNewCurrency(jsonValue);
            setIsShowAddModal(false);
          }}
        >
          <Form.Item label="Currency data">
            <Editor
              ace={ace}
              theme="ace/theme/github"
              value={jsonValue}
              mode="code"
              onChange={(data) => {
                setJsonValue(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title="Edit currency"
        centered
        visible={isShowEditModal}
        onCancel={() => {
          setIsShowEditModal(false);
          setSelectedCurrency(null);
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            // @ts-ignore
            await updateCurrency(selectedCurrency);
            setIsShowEditModal(false);
            setSelectedCurrency(null);
          }}
        >
          <Form.Item label="Currency data">
            <Editor
              ref={editEditorRef}
              ace={ace}
              theme="ace/theme/github"
              value={{}}
              mode="code"
              onChange={(data) => {
                setSelectedCurrency(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default Currencies;
