import { ISystemFields } from "./systemFields";

export interface IInternalTransferToInitiate {
  fromEntityId: string;
  toEntityId: string; // house
  amount: number;
  currency: string;
  type: TInternalTransferType;
  reason?: string;
}

export interface IInternalTransfer extends IInternalTransferToInitiate {
  status: "pending" | "completed" | "error";
  externalRefs: {
    ccId: string;
  };
}

export enum TRANSACTION_TYPE {
  /** The initial amount required to initiate a contract. This could be Flex Fee or Deposit. */
  prepayment = "prepayment",
  /** The funding required to send a payment. Always in sellCurrency. */
  transfer = "transfer",
  /** The funding required to initiate a Bulk Payment Run. Always in sellCurrency. */
  bulkPayment = "bulkPayment",
  /** The funding required to initiate a conversion (Simple Exchange). */
  simpleExchange = "simpleExchange",
  /**
   * The margin we charge our clients for a Transfer/Payment between two currencies.
   * This is the difference between what we quote to the user and what Currency Cloud charges us.
   */
  transferFee = "transferFee",
  /** Flat fee per sellCurrency which we charge per transfer using the SWIFT payment rails. */
  swiftFee = "swiftFee",
  /**
   * The margin we charge for a for a conversion (Simple Exchange).
   * This is the difference between what we quote to the user and what Currency Cloud charges us.
   */
  simpleExchangeFee = "simpleExchangeFee",
  /** Moving funds between subAccounts and the safeguarding subAccount */
  safeguarding = "safeguarding",
  /** Any funding coming into the house account or sub-accounts which cannot be matched to an
   * expected cashflow will be labelled as an UNKNOWN FUNDING. */
  unknownFunding = "UNKNOWN FUNDING",
  /** Manually created via e.g. the Admin Portal. */
  manual = "manual",
}

export type TInternalTransferType =
  | TRANSACTION_TYPE.transferFee
  | TRANSACTION_TYPE.swiftFee
  | TRANSACTION_TYPE.prepayment
  | TRANSACTION_TYPE.simpleExchangeFee
  | TRANSACTION_TYPE.safeguarding
  | TRANSACTION_TYPE.manual
  | TRANSACTION_TYPE.unknownFunding;

export const internalTransferTypes = [
  TRANSACTION_TYPE.transferFee,
  TRANSACTION_TYPE.swiftFee,
  TRANSACTION_TYPE.prepayment,
  TRANSACTION_TYPE.simpleExchangeFee,
  TRANSACTION_TYPE.safeguarding,
  TRANSACTION_TYPE.manual,
  TRANSACTION_TYPE.unknownFunding,
];

export interface ITransferHighRiskCountryCheck {
  transferId: string;
  isResolved: boolean;
  comments?: string;
  type: 'highRiskCountry';
}

// add more checks here as needed
export type TTransferCheck = ITransferHighRiskCountryCheck;
export type TTransferCheckDoc = TTransferCheck & ISystemFields;
