import { FC } from "react";
import { Tag } from "antd";

const SenderStatus: FC<{ status: string }> = ({ status }) => {
  const isApproved = status === "approved";
  const isRejected = status === "rejected";
  const isDraft = status === "draft";
  const isReviewRequired = status === "requiresReview";
  const isOther = !isApproved && !isRejected && !isDraft && !isReviewRequired;

  return (
    <>
      {isApproved && <Tag color="green">APPROVED</Tag>}
      {isRejected && <Tag color="red">REJECTED</Tag>}
      {isDraft && <Tag color="grey">DRAFT</Tag>}
      {isReviewRequired && <Tag color="volcano">Requires Review</Tag>}
      {isOther && status}
    </>
  );
};

export default SenderStatus;
