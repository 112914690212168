import axios from "axios";

import { openDoc, Notify } from "utils";
import { ICurrency, IResponse } from "types";

import db from "services/firestore";

export const getNonTradingDays = async () => {
  try {
    const data = await db
      .collection("referenceData")
      .doc("nonTradingDays")
      .get()
      .then((doc) => openDoc(doc));

    return data;
  } catch (error) {
    Notify.error(error.message);
  }
};

export const getCountries = async () => {
  try {
    const data = await db
      .collection("referenceData")
      .doc("countries")
      .get()
      .then((doc) => doc.data());

    return data;
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface GetRatingsParams {
  sellCurrency: ICurrency["code"];
  buyCurrency: ICurrency["code"];
  rate: number;
}

export const getRatings = async ({
  rate,
  sellCurrency,
  buyCurrency,
}: GetRatingsParams) => {
  try {
    const { data } = await axios.get<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/analysis/ratings?sell_currency=${sellCurrency}&buy_currency=${buyCurrency}&current_rate=${rate}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface GetRateAnalysisParams {
  sellCurrency: ICurrency["code"];
  buyCurrency: ICurrency["code"];
  sellAmount: number;
  buyAmount: number;
}

export const getRateAnalysis = async ({
  sellAmount,
  buyAmount,
  sellCurrency,
  buyCurrency,
}: GetRateAnalysisParams) => {
  try {
    const { data } = await axios.get<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/analysis/rate-analysis?sell_currency=${sellCurrency}&buy_currency=${buyCurrency}&sell_amount=${sellAmount}&buy_amount=${buyAmount}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface GetPotentialSavingsParams {
  sellCurrency: ICurrency["code"];
  buyCurrency: ICurrency["code"];
  buyAmount: number;
}

export const getPotentialSavings = async ({
  buyAmount,
  sellCurrency,
  buyCurrency,
}: GetPotentialSavingsParams) => {
  try {
    const { data } = await axios.get<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/analysis/potential-savings?sell_currency=${sellCurrency}&buy_currency=${buyCurrency}&buy_amount=${buyAmount}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface GetPotentialSavingsWithDateParams
  extends GetPotentialSavingsParams {
  tenorDate: string;
}

export const getPotentialSavingsWithDate = async ({
  buyAmount,
  sellCurrency,
  buyCurrency,
  tenorDate,
}: GetPotentialSavingsWithDateParams) => {
  try {
    const { data } = await axios.get<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/analysis/potential_savings?sell_currency=${sellCurrency}&buy_currency=${buyCurrency}&buy_amount=${buyAmount}&tenor_date=${tenorDate}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface GetRateAnalysisTableParams {
  rate: number;
  deposit: number;
  bookingFee: number;
  buyAmount: number;
}

export const getRateAnalysisTable = async ({
  buyAmount,
  deposit,
  bookingFee,
  rate,
}: GetRateAnalysisTableParams) => {
  try {
    const { data } = await axios.get<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/analysis/rate-analysis-table?buy_amount=${buyAmount}&rate=${rate}&deposit=${deposit}&booking_fee=${bookingFee}&conversion_fee=0.004`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export const subscribeToPageAccess = (callback) => {
  try {
    const unsubscribe = db
      .collection("referenceData")
      .doc("roleBasedAdminPageAccess")
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};
