import { FC, useState, useEffect } from "react";
import { Table, Typography } from "antd";

import dayjs from "dayjs";
import { Link } from "react-router-dom";
import "./SendersList.css";
import { openQueryWithTimestamp } from "utils";

import db from 'services/firestore';
import SenderStatus from "components/SenderStatus/SenderStatus";


const getSenders = async () => {
  try {
    const senders = await db
      .collection("senders")
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created"]));

    return senders;
  } catch (error) {
    console.log(error);
  }
};

const getSendersRequiringReview = async () => {
  try {
    const senders = await db
      .collection("senders")
      .where("status", "==", "requiresReview")
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created"]));

    return senders;
  } catch (error) {
    console.log(error);
  }
};

const getApprovedSenders = async () => {
  try {
    let senders = await db
      .collection("senders")
      .where("lastReviewed", "!=", null)
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created"]));

    senders = senders.filter(
      (sender) => sender.status !== "requiresReview"
    );

    return senders;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const sendersColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/sender-detail/" + record.id}>{text}</Link>
        </span>
      );
    },
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => <SenderStatus status={status} />,
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
];

const SendersList: FC = () => {
  const [senders, setSenders] = useState([]);
  const [sendersRequiringReview, setSendersRequiringReview] = useState([]);
  const [previouslyReviewedSenders, setPreviouslyReviewedSenders] =
    useState([]);

    useEffect(() => {
      getSenders().then((res) => {
        if (res) {
          setSenders(res);
        }
      });
    }, []);

  useEffect(() => {
    getSendersRequiringReview().then((res) => {
      if (res) {
        setSendersRequiringReview(res);
      }
    });
  }, []);

  useEffect(() => {
    getApprovedSenders().then((res) => {
      if (res) {
        setPreviouslyReviewedSenders(res);
      }
    });
  }, []);

  return (
    <div>
      <div>
        <Table
          title={() => <Title level={2}>Senders Requiring Review</Title>}
          columns={sendersColumns}
          dataSource={sendersRequiringReview}
        />
      </div>
      <div>
        <Table
          title={() => <Title level={2}>Senders Previously Reviewed</Title>}
          columns={sendersColumns}
          dataSource={previouslyReviewedSenders}
        />
      </div>
      <div>
        <Table
          title={() => <Title level={2}>All Senders</Title>}
          columns={sendersColumns}
          dataSource={senders}
        />
      </div>
    </div>
  );
};

export default SendersList;
