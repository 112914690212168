import React, { useEffect, useState } from "react"

import {
  Table,
} from "antd";
import { IWriteEventLog } from "../interfaces"
import { writeEventLogColumns } from "../tableColumns";
import { subscribeToEntityWritePreviousAttemptLogs } from "services/firebase";

// we don't want action buttons for this table
const columns = [...writeEventLogColumns].splice(0, writeEventLogColumns.length-1);

const PreviousAttemptsTable: React.FC<{record: IWriteEventLog}> = ({record}) => {
  const [previousWriteEventLogs, setPreviousWriteEventLogs] = useState<IWriteEventLog[]>()

  useEffect(() => {
    if(record.id){
      const unsubscribe = subscribeToEntityWritePreviousAttemptLogs({
        docId: record.id, 
        callback: (data) => {
          setPreviousWriteEventLogs(data.map((ele, index) => ({...ele, key: index})))
        }
      })

      return () => {
        unsubscribe?.();
      };
    }
  }, [record, setPreviousWriteEventLogs])

  return (
    <Table
      columns={columns}
      dataSource={previousWriteEventLogs
    }/>
  )
}

export default PreviousAttemptsTable