import axios from "axios";

import { ICurrency } from "types";
import { parseRateWithPrecision } from "utils";
import auth from "../auth";

export interface GetRateParams {
  sellCurrency: ICurrency["code"];
  buyCurrency: ICurrency["code"];
  entityId?: number;
}

export const getRate = async ({
  sellCurrency,
  buyCurrency,
  entityId,
}: GetRateParams) => {
  try {
    const token = await auth.currentUser.getIdToken(true);
    let url = `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/rates/${sellCurrency}${buyCurrency}`;
    if (entityId) {
      url += `?entity_id=${entityId}`;
    }
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (data?.success) {
      return {
        ...data,
        data: {
          ...data.data,
          rate: parseFloat(parseRateWithPrecision(data.data.rate)),
        },
      };
    }
  } catch (error) {
    console.warn(error.message);
  }
};
