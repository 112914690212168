import { FC, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Drawer,
  Col,
  Row,
  message,
} from "antd";

import "antd/dist/antd.css";

import { Firebase } from "services";
import { CONTRACT_STATUS, IContract } from "types";

const { Option } = Select;


interface OwnProps {
  contract: IContract;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const EditContract: FC<OwnProps> = ({
  contract,
  isVisible,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const form = Form.useForm()[0];

  useEffect(() => {
    if (contract && isVisible) {
      form.setFieldsValue(contract);
      setStatus(contract.status);
    }
  }, [form, contract, isVisible]);

  const onUpdateContract = async ({ expiryDate }) => {
    setIsLoading(true);

    try {
      if (contract.expiryDate === expiryDate && contract.status === status) {
        throw new Error("No changes detected");
      }

      const updateData: Partial<IContract> = {};
      if (expiryDate && contract.expiryDate !== expiryDate) {
        updateData.expiryDate = expiryDate;
      }
      if (status && contract.status !== status) {
        updateData.status = status;
      }
      const response = await Firebase.updateContract({
        contractId: contract.id,
        updateData,
      });

      if (response.data.success) {
        message.success(`Contract ${contract.id} updated successfully`);
        onClose();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={`Update Contract Details`}
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={async ({ fileUpload, ...values }) => {
          // submit logic here
          onUpdateContract(values);
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="expiryDate" label="Expiry Date">
              <Input style={{ width: "100%" }} placeholder="yyyy-mm-dd" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
        <Select
          value={status}
          placeholder="Choose desired action"
          style={{
            flex: 1,
            marginRight: 16,
          }}
          onChange={(value) => setStatus(value)}
        >
          <Option value={CONTRACT_STATUS.ready_to_use}>Ready To Use</Option>
          <Option value={CONTRACT_STATUS.cancelled}>Cancelled</Option>
          <Option value={CONTRACT_STATUS.expired}>Expired</Option>
        </Select>
      </Row>

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

    </Drawer>
  );
};

export default EditContract;
