import { Button, Row, Typography } from "antd";
import { FC, useMemo } from "react";
import { Route } from "react-router-dom";
import { Firebase } from "services";
import { useStoreState } from "state";
import { ICustomPrivateRoute, checkPermissions } from "utils";

const { Title } = Typography;

const PrivateRoute: FC<ICustomPrivateRoute> = ({
  pageKey,
  component: Component,
  ...rest
}) => {
  const userRoles = useStoreState((state) => state.UserState.user?.roles);
  const { roleBasedAdminPageAccess } = useStoreState(
    (state) => state.ReferenceDataState
  );

  const { allowRender, allowWrite } = useMemo(
    () =>
      checkPermissions({
        pageKey,
        roles: userRoles,
        roleBasedAdminPageAccess,
      }),
    [pageKey, roleBasedAdminPageAccess, userRoles]
  );

  if (pageKey && !allowRender) {
    return (
      <Route
        {...rest}
        render={() => (
          <>
            <Row align="middle" justify="center">
              <Title level={4}>You have no access to this route</Title>
            </Row>
            <Row align="middle" justify="center">
              <Button onClick={() => Firebase.auth.signOut()}>Log out</Button>
            </Row>
          </>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} allowWrite={allowWrite} />}
    />
  );
};

export default PrivateRoute;
