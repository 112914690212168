import React, { FC, useState, useEffect } from "react";
import { Badge, Button, Descriptions, Table, Typography } from "antd";

import dayjs from "dayjs";
import "./Transfer.css";
import {
  toCurrencyNumber,
  openDoc,
  openDocWithTimestamp,
  openQueryWithTimestamp,
  parseRateWithPrecision,
  TCustomRouteComponentProps,
} from "utils";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import db from "services/firestore";
import {
  TRANSFER_STATUS,
  IEntity,
  IInvoice,
  INVOICE_STATUS,
  ITransfer,
} from "types";
import EditTransfer from "./components/EditTransfer";
import DocViewer from "pages/Overview/components/DocViewer";
import * as Firebase from "../../services/firebase";
import Checks from "./components/Checks/Checks";

const subscribeToTransfer = (transferId, callback) => {
  try {
    const unsubscribe = db
      .collection("transfers")
      .doc(transferId)
      .onSnapshot((doc) =>
        callback(openDocWithTimestamp(doc, ["_created", "_updated"]))
      );
    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToInvoices = (transferId, callback) => {
  try {
    const unsubscribe = db
      .collection("invoices")
      .where("transferIds", "array-contains", transferId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntity = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const Transfer: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null as IEntity);
  const [transfer, setTransfer] = useState(null as ITransfer);
  const [invoices, setInvoices] = useState([]);
  const [modifyTransfer, setModifyTransfer] = useState(false);
  const [docToView, setDocToView] = useState(null);

  // @ts-ignore
  const { transfer_id } = useParams();

  const getInvoiceColumns = (setDocToView) => {
    const InvoicesColumns = [
      {
        title: "Created",
        dataIndex: "_created",
        key: "_created",
        render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
      },
      {
        title: "Original Amount",
        dataIndex: "totalAmount",
        key: "totalAmount",
        render: (text, record: IInvoice) => {
          return (
            <div className="currencyAmount">
              {toCurrencyNumber(record.totalAmount)}&nbsp;
              <b>{record.currency}</b>
            </div>
          );
        },
      },
      {
        title: "Due Amount",
        dataIndex: "amountDue",
        key: "amountDue",
        render: (text, record: IInvoice) => {
          return (
            <div className="currencyAmount">
              {toCurrencyNumber(record.amountDue)}&nbsp;<b>{record.currency}</b>
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record: IInvoice) => {
          let status = record.status.toString();
          status === INVOICE_STATUS.authorised && (status = "Authorised");
          status === INVOICE_STATUS.paymentScheduled &&
            (status = "Payment Scheduled");
          return <>{status}</>;
        },
      },
      {
        title: "Recipient ID",
        dataIndex: "contactId",
        key: "contactId",
        render: (text, record: IInvoice) => {
          return (
            <span>
              <Link to={"/app/recipient-detail/" + record.contactId}>
                {text}
              </Link>
            </span>
          );
        },
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (text, record) => {
          return (
            <span>
              <Link to="#" onClick={() => setDocToView(record)}>
                {text}
              </Link>
            </span>
          );
        },
      },
      {
        title: "Entity ID",
        dataIndex: "_owner",
        key: "_owner",
        render: (text, record) => {
          return (
            <span>
              <Link to={"/app/entity-detail/" + record._owner}>{text}</Link>
            </span>
          );
        },
      },
      ...(allowWrite
        ? [
            {
              title: "Actions",
              key: "actions",
              render: (_, record: IInvoice) => {
                return (
                  <span>
                    {(transfer.status === TRANSFER_STATUS.paymentFailed ||
                      transfer.status === TRANSFER_STATUS.paymentCancelled) &&
                      record.payments?.map(
                        (payment, index) =>
                          transfer.id === payment.transferId && (
                            <Button
                              style={{
                                margin: "10px 25px 0px 0px",
                                float: "right",
                              }}
                              onClick={async () => {
                                await Firebase.cancelPaymentFromExternalInvoice(
                                  {
                                    entityId: record._owner,
                                    invoiceId: record.id,
                                    paymentId: payment.sourceSystemId,
                                    sourceSystemInvoiceId:
                                      record.externalRefs.sourceSystemId,
                                  }
                                );
                              }}
                            >
                              Remove payment {index} {payment.amount} from
                              invoice
                            </Button>
                          )
                      )}
                  </span>
                );
              },
            },
          ]
        : []),
    ];

    return InvoicesColumns;
  };

  useEffect(() => {
    if (transfer_id) {
      const unsubscribeTransfer = subscribeToTransfer(transfer_id, (data) =>
        setTransfer(data)
      );

      const unsubscribeInvoices = subscribeToInvoices(transfer_id, (data) =>
        setInvoices(data)
      );

      return () => {
        unsubscribeTransfer?.();
        unsubscribeInvoices?.();
      };
    }
  }, [transfer_id]);

  useEffect(() => {
    if (transfer) {
      const unsubscribe = subscribeToEntity(transfer._owner, (data) =>
        setEntity(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [transfer]);

  return (
    <div>
      <>
        <EditTransfer
          transfer={transfer}
          isVisible={modifyTransfer}
          onClose={() => setModifyTransfer(false)}
        />
        <DocViewer
          doc={docToView}
          isVisible={!!docToView}
          onClose={() => setDocToView(null)}
        />
      </>
      <>
        {entity && <Title>Transfer Details</Title>}

        <p>
          {transfer &&
            `${transfer.id} - CC ID: ${
              transfer.externalRefs?.ccId || "Not available yet"
            } - CC Conversion ID: ${
              transfer.rateType === "noRate"
                ? "Same CCY Transfer"
                : transfer.externalRefs?.ccConversionId || "Not available yet"
            }`}{" "}
          <br />
          {entity && (
            <>
              For{" "}
              <Link to={"/app/entity-detail/" + entity.id}>{entity.name}</Link>
            </>
          )}
        </p>
        {transfer && (
          <Descriptions title="Transfer Details" bordered>
            <Descriptions.Item label="Sell">
              {transfer.sellCurrency} {toCurrencyNumber(transfer.sellAmount)}
            </Descriptions.Item>
            <Descriptions.Item label="Contract ID">
              <Link to={"/app/contract/" + transfer.contractId}>
                {transfer.contractId}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {dayjs(transfer._created).format("YYYY-MM-DD H:mm")}
            </Descriptions.Item>
            <Descriptions.Item label="Buy">
              {transfer.buyCurrency} {toCurrencyNumber(transfer.buyAmount)}
            </Descriptions.Item>
            <Descriptions.Item label="Bulk ID">
              {transfer.bulkPaymentId || " "}
            </Descriptions.Item>
            <Descriptions.Item label="Updated">
              {dayjs(transfer._updated).format("YYYY-MM-DD H:mm")}
            </Descriptions.Item>
            <Descriptions.Item label="Rate">
              {parseRateWithPrecision(transfer.rate)}
            </Descriptions.Item>
            <Descriptions.Item label="Rate Type">
              {transfer.rateType}
            </Descriptions.Item>
            <Descriptions.Item label="PayBy Date Time">
              {transfer.payByDate} {transfer.payByTimeGMT}
            </Descriptions.Item>
            <Descriptions.Item label="Recipient">
              <Link to={"/app/recipient-detail/" + transfer.recipientId}>
                {transfer.recipient?.recipientName || transfer.recipientId}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item label="Transfer Type">
              {transfer.transferType}
            </Descriptions.Item>
            <Descriptions.Item label="Scheduled Payment Date">
              {transfer.scheduledPaymentDate}
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={3}>
              {transfer.status === TRANSFER_STATUS.paymentSent && (
                <Badge status="success" text="Sent" />
              )}
              {transfer.status === TRANSFER_STATUS.paymentConfirmed && (
                <Badge status="success" text="Confirmed" />
              )}
              {transfer.status === TRANSFER_STATUS.paymentCancelled && (
                <Badge status="error" text="Cancelled" />
              )}
              {transfer.status === TRANSFER_STATUS.paymentFailed && (
                <Badge status="error" text="Failed" />
              )}
              {transfer.status === TRANSFER_STATUS.timedOut && (
                <Badge status="error" text="Timed Out" />
              )}
              {transfer.status === TRANSFER_STATUS.initiated && (
                <Badge status="processing" text="Initiated" />
              )}
              {transfer.status === TRANSFER_STATUS.awaitingPayment && (
                <Badge status="processing" text="Awaiting Funding" />
              )}
              {transfer.status === TRANSFER_STATUS.funded && (
                <Badge status="processing" text="Funded" />
              )}
              {transfer.status === TRANSFER_STATUS.approved && (
                <Badge status="processing" text="Approved" />
              )}
              {transfer.status === TRANSFER_STATUS.processing && (
                <Badge color="lime" text="Processing" />
              )}
              {allowWrite && (
                <Button
                  type="primary"
                  style={{ float: "right" }}
                  onClick={() => setModifyTransfer(true)}
                >
                  Modify
                </Button>
              )}
            </Descriptions.Item>
          </Descriptions>
        )}

        <Table
          title={() => <Title level={2}>Invoices</Title>}
          columns={getInvoiceColumns(setDocToView)}
          dataSource={invoices}
        />
      </>

      <Checks transferId={transfer_id} />
    </div>
  );
};

export default Transfer;
