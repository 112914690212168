import React, { FC, useState, useEffect } from "react";
import { Table, Typography } from "antd";

import dayjs from "dayjs";
import "./Onboarding.css";
import { openQueryWithTimestamp } from "utils";
import { Link } from "react-router-dom";

import db from 'services/firestore';
import { ENTITY_STATUS, ENTITY_SUB_STATUS_AFTER_ONBOARDING, IEntity } from "types";

const getEntities = async () => {
  try {
    const entities = await db
      .collection("entities")
      .where("enabled", "==", false)
      // .where("status", "==", "onboardingStep4Completed")
      // .orderBy("_created", "desc")
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created", "_updated"]));

    return entities.sort((a, b) => b._created.toISOString().localeCompare(a._created.toISOString()));
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const Onboarding: FC = () => {
  const [entities, setEntities] = useState<IEntity[]>([]);

  useEffect(() => {
    getEntities().then((res) => {
      if (res) {
        setEntities(res);
      }
    });
  }, []);

  const entitiesWithSanctionUpdatesColumns = [
    {
      title: "Updated",
      dataIndex: "_updated",
      key: "_updated",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/onboarding-detail/" + record.id}>{text}</Link>
          </span>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
  ];

  const entitiesRegisteredColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/onboarding-detail/" + record.id}>{text}</Link>
          </span>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
  ];

  const entitiesNotReadyColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/onboarding-detail/" + record.id}>{text}</Link>
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "User email",
      dataIndex: "_createdBy",
      key: "_createdBy",
      render: (text, record) => {
        return (
          <>
          <RowComp text={text} />
          </>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/onboarding-detail/" + record.id}>{text}</Link>
          </span>
        );
      },
    },
  ];

  const RowComp = ({ text, ...props }) => {
    const [user, setUser] = useState(null);
    
    useEffect(() => {
      const getUser = async (userId) => {
        try {
          return db
            .collection('users')
            .doc(userId)
            .get()
            .then((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              }
            });
        } catch (error) {
          console.log(error);
          return null;
        }
      };

      getUser(text).then(res => setUser(res));
    }, [text]);

    return (<>{user?.email}</>)
  };

  

  return (
    <>
      <div>
        <Table
          title={() => (
            <Title level={2}>Entities requiring Review after CA Update</Title>
          )}
          columns={entitiesWithSanctionUpdatesColumns}
          dataSource={entities.filter(entity => entity.status === ENTITY_STATUS.onboarded && entity.subStatusAfterOnboarding === ENTITY_SUB_STATUS_AFTER_ONBOARDING.onHold)}
        />
      </div>

      <div>
        <Table
          title={() => (
            <Title level={2}>Entities requiring Onboarding Review</Title>
          )}
          columns={entitiesRegisteredColumns}
          dataSource={entities.filter(entity => entity.status === ENTITY_STATUS.onboardingStep4Completed)}
        />
      </div>

      <div>
        <Table
          title={() => (
            <Title level={2}>Entities which haven't completed registration</Title>
          )}
          columns={entitiesNotReadyColumns}
          dataSource={
            entities
              .filter(entity => entity.status !== ENTITY_STATUS.onboardingStep4Completed && entity.status !== ENTITY_STATUS.rejected)
              .sort((a, b) => {
                if (a.status > b.status) return -1;
                if (b.status > a.status) return 1;
                return 0;
              })
          }
        />
      </div>

      <div>
        <Table
          title={() => (
            <Title level={2}>Rejected entities</Title>
          )}
          columns={entitiesNotReadyColumns}
          dataSource={
            entities
              .filter(entity => entity.status === ENTITY_STATUS.rejected)
          }
        />
      </div>
    </>
  );
};

export default Onboarding;
