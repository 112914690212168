import { Table } from "antd";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import db from "services/firestore";
import { TTransferCheck } from "types/transfers";
import { openQueryWithTimestamp } from "utils";

const subscribeToUnresolvedTransferChecks = (
  callback: (checks: TTransferCheck[]) => void
) => {
  db.collectionGroup("checks")
    .where("transferId", "!=", "")
    .onSnapshot((querySnapshot) => {
      return callback(
        openQueryWithTimestamp<TTransferCheck>(querySnapshot, [
          "_created",
          "_updated",
        ])
      );
    });
};

const TransfersReview: FC = () => {
  const [checks, setChecks] = useState<TTransferCheck[]>([]);
  useEffect(() => {
    subscribeToUnresolvedTransferChecks((checks) => {
      setChecks(checks.filter((check) => check.isResolved === false));
    });
  }, []);

  const checksColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Updated",
      dataIndex: "_updated",
      key: "_updated",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "Transfer ID",
      dataIndex: "transferId",
      key: "transferId",
      render: (text, record) => (
        <span>
          <Link to={`/app/transfer/${record.transferId}`}>{text}</Link>
        </span>
      ),
    },
  ];

  return (
    <Table
      title={() => <Title level={2}>Transfer checks requiring review</Title>}
      columns={checksColumns}
      dataSource={checks}
      pagination={false}
    />
  );
};

export default TransfersReview;
